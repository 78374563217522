import axios from "axios";
import setJWTToken from "../securityUtils/setJWTToken";
import jwt_decode from "jwt-decode";
import { localhost, GET_ERRORS } from "../actions/index";
//for authorization
export const EMAIL_CHANGED = "email_changed";
export const PASSWORD_CHANGED = "password_changed";
export const LOGIN_USER_FAIL = "login_user_fail";
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_STATUS = "LOGIN_STATUS";
export const LOGOUT_USER = "LOGOUT_USER";
export const USER_NAME_SESSION_ATTRIBUTE_NAME = "authenticatedUser";
export const SET_CURRENT_USER = "SET_CURRENT_USER";

export const setCurrentUser = (token) => async (dispatch) => {
  try {
    let decoded = jwt_decode(token);

    dispatch({
      type: SET_CURRENT_USER,
      payload: decoded,
    });
  } catch (err) {
    if (err.response && err.response.data) {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    }
  }
};

export function setupAxiosInterceptors(token) {
  axios.interceptors.request.use((config) => {
    if (token) {
      config.headers.authorization = token;
    }
    return config;
  });
}

export async function userLogout(dispatch) {
  // if (
  //   sessionStorage.getItem("jwtToken") &&
  //   sessionStorage.getItem("jwtToken") !== null
  // ) {
  sessionStorage.clear();
  //localStorage.clear();
  await setJWTToken(false);
  window.location.href = "/";
  // }
}
