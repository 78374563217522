import axios from "axios";
import { BASE_URL } from "./URL";

export const getProjectList = async (userId) => {
    try {
        const info = await axios.get(`${BASE_URL}api/users/projectlist/${userId}`);        
        return info;
    } catch (err) {
        return err;
    }
};
export const getBillingTrackerProjectList = async (userId) => {
    try {
        const info = await axios.get(`${BASE_URL}api/projects/projectlist`);
        
        return info;
    } catch (err) {
        return err;
    }
};

export const saveProjectList = async (params) => {
    const req = {
        url: `${BASE_URL}api/users/projectlist`,
        method: "PUT",
        data: params
    };
    try {
        const info = await axios(req);
        return info;
    } catch (err) {
        return err;
    }
};

export const updateBillingTrackerProjectList = async (params) => {
    const req = {
        url: `${BASE_URL}api/projects/projectlist`,
        method: "PUT",
        data: params
    };
    try {
        const info = await axios(req);
        return info;
    } catch (err) {
        return err;
    }
};

export const getThreshold = async (userId) => {
    try {
        const info = await axios.get(`${BASE_URL}api/settings/threshold`);
        
        return info;
    } catch (err) {
        return err;
    }
};

export const updateThreshold = async (params) => {
    const req = {
        url: `${BASE_URL}api/settings/updatethreshold`,
        method: "PUT",
        data: params
    };
    try {
        const info = await axios(req);
        return info;
    } catch (err) {
        return err;
    }
};