import { combineReducers } from "redux";
import errorReducer from "./errorReducer";
import dashboardReducer from "./reducer-dashboard";
import securityReducer from "./securityReducer";
import userdReducer from "./reducer-user";
import userProjectReducer from "./userProjectReducer";

//import setPatientProgramReducer from "./reducer-setPatientProgram";

const allReducers = combineReducers({
    errors: errorReducer,
    errors: errorReducer,
    security: securityReducer,
    errors: errorReducer,
    security: securityReducer,
    dashboard: dashboardReducer,
    user: userdReducer,
    userProject: userProjectReducer
});

export default allReducers;
