import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchThreshold, saveThreshold } from '../../../actions/index';
import { Row } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { Slider } from 'rsuite';
import 'rsuite/Slider/styles/index.css';
import { alertService } from './../../GlobalComponent/alertService';

import { NumericFormat } from 'react-number-format';
import classnames from "classnames";

class ThresholdSetting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            minorThreshold: "",
            minorThresholdHelperText: "",
            majorThreshold: "",
            majorThresholdHelperText: "",
            criticalThreshold: "",
            criticalThresholdHelperText: "",
            error: this.props.error,
            thresholdList: "",
            // editUserInfo: {...this.props.EditUser}
        };
    }

    componentDidMount() {
        this.props.fetchThreshold();
    }

    componentDidUpdate(prevProps) {
        if (this.props.thresholdList && prevProps.thresholdList !== this.props.thresholdList) {
            this.setState({ thresholdList: this.props.thresholdList });
            setTimeout(() => {
                this.state.thresholdList.forEach(element => {
                    if (element.name == "minorThreshold") {
                        this.setState({ "minorThreshold": element.value });
                    }
                    if (element.name == "majorThreshold") {
                        this.setState({ "majorThreshold": element.value });
                    }
                    if (element.name == "criticalThreshold") {
                        this.setState({ "criticalThreshold": element.value });
                    }
                });
            }, 200)
        }
        if (this.props.isUpdatedSuccess && prevProps.isUpdatedSuccess !== this.props.isUpdatedSuccess) {
            alertService.success("Threshold value successfully submitted", { autoClose: true });
        }
    }
    onChangeValueHandler = (e) => {
        let value = '';
        value = e.target.value.trim();
        this.setState({
            [e.target.id]: value,
            [`${e.target.id}HelperText`]: "",
        });
    };

    onChangeValueRangeHandler = (val, id = '') => {

        this.setState({
            [id]: val,
            [`${id}HelperText`]: ""
        });
    };

    ValidateData = async (e) => {
        this.setState({
            minorThresholdHelperText: "",
            majorThresholdHelperText: "",
            criticalThresholdHelperText: ""
        });

        if (this.state.minorThreshold < 0) {
            this.setState({ majorhresoldHelperText: "Invalid Minor Threshold value" });
            return false;
        }
        if (this.state.majorThreshold <= 0 || this.state.majorThreshold <= this.state.minorThreshold) {
            this.setState({ majorThresholdHelperText: "Major Threshold should be greater than Minor Threshold" });
            return false;
        }
        if (this.state.criticalThreshold <= 0 || this.state.criticalThreshold <= this.state.majorThreshold) {
            this.setState({ criticalThresholdHelperText: "Critical Threshold should be greater than Major Threshold" });
            return false;
        }

        let thersholdVal = this.state.thresholdList.map(element => {
            if (element.name == "minorThreshold") {
                element.value = this.state.minorThreshold
            }
            if (element.name == "majorThreshold") {
                element.value = this.state.majorThreshold
            }
            if (element.name == "criticalThreshold") {
                element.value = this.state.criticalThreshold
            }
            return element;
        });
        console.log("thersholdVal::::", thersholdVal);
        this.props.saveThreshold(thersholdVal);
    };

    render() {
        return (
            <>
                <div className="pt-76 bg-dark-theme bg-light-theme grow-1 flex flex-col">
                    <div class="p-4 projectlist-popup">
                        <Container>
                            <div className="d-flex flex-wrap align-items-center mb-3">
                                <h2 className="">Threshold Value</h2>
                            </div>

                            <Form on>
                                <Row className='threshold-row'>
                                    <Col sm={6}>
                                        <Form.Group className="mb-4" >
                                            <Form.Label>Price Range for Minor</Form.Label>

                                            {this.state.minorThreshold != '' && this.state.minorThreshold != '' &&
                                                <>
                                                    <Slider
                                                        id="minorThreshold"
                                                        progress
                                                        min={1}
                                                        max={100}
                                                        className={classnames("font-18", {
                                                            "is-invalid": this.state.minorThresholdHelperText,
                                                        })}
                                                        value={this.state.minorThreshold}
                                                        graduated={false}
                                                        onChange={(val, e) => this.onChangeValueRangeHandler(val, 'minorThreshold')}
                                                    />
                                                    <div>
                                                        <strong>${this.state.minorThreshold}</strong>
                                                    </div>
                                                </>
                                            }
                                            {this.state.minorThresholdHelperText && (
                                                <div className="invalid-feedback">
                                                    {this.state.minorThresholdHelperText}
                                                </div>
                                            )}

                                        </Form.Group>
                                    </Col>
                                    <Col sm={6}>
                                        <Form.Group className="mb-4" >
                                            <Form.Label>Price Range for Major</Form.Label>
                                            {this.state.majorThreshold != '' && this.state.majorThreshold != '' &&
                                                <>
                                                    <Slider
                                                        id="majorThreshold"
                                                        progress
                                                        min={1}
                                                        max={100}
                                                        className={classnames("font-18", {
                                                            "is-invalid": this.state.majorThresholdHelperText,
                                                        })}
                                                        value={this.state.majorThreshold}
                                                        graduated={false}
                                                        onChange={(val, e) => this.onChangeValueRangeHandler(val, 'majorThreshold')}
                                                    />
                                                    <div>
                                                        <strong>${this.state.majorThreshold}</strong>
                                                    </div>
                                                </>
                                            }
                                            {this.state.majorThresholdHelperText && (
                                                <div className="invalid-feedback">
                                                    {this.state.majorThresholdHelperText}
                                                </div>
                                            )}

                                        </Form.Group>
                                    </Col>
                                    <Col sm={6}>
                                        <Form.Group className="mb-4" >
                                            <Form.Label>Price Range for Critical</Form.Label>
                                            {this.state.criticalThreshold != '' && this.state.criticalThreshold != '' &&
                                                <>
                                                    <Slider
                                                        id="criticalThreshold"
                                                        progress
                                                        min={1}
                                                        max={100}
                                                        value={this.state.criticalThreshold}
                                                        className={classnames("font-18", {
                                                            "is-invalid": this.state.criticalThresholdHelperText,
                                                        })}
                                                        graduated={false}
                                                        onChange={(val, e) => this.onChangeValueRangeHandler(val, 'criticalThreshold')}
                                                    />
                                                    <div>
                                                        <strong>${this.state.criticalThreshold}</strong>
                                                    </div>
                                                </>
                                            }
                                            {this.state.criticalThresholdHelperText && (
                                                <div className="invalid-feedback">
                                                    {this.state.criticalThresholdHelperText}
                                                </div>
                                            )}

                                        </Form.Group>
                                    </Col>

                                </Row>
                            </Form>

                            <Row>
                                <Col xs={12}>
                                    <Button variant="primary" onClick={(e) => {
                                        e.preventDefault();
                                        this.ValidateData(e);
                                    }} disabled={this.state.minorThreshold == '' ? true : false}>
                                        Submit
                                    </Button>
                                </Col>
                            </Row>

                        </Container>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        //provider: state.provider.providerList,
        currentUser: state.security.user,
        thresholdList: state.userProject.thresholdList,
        isUpdatedSuccess: state.userProject.isThresholdUpdatedSuccess
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            fetchThreshold,
            saveThreshold
        },
        dispatch
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(ThresholdSetting);
