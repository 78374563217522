// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup,OAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

let firebaseConfig = {
  // apiKey: "AIzaSyDvutzVHWrwLMkKY-9EON5R62iS27yjTi4",
  // authDomain: "dashboard-for-gcp-billing.firebaseapp.com",
  // databaseURL: "",
  // projectId: "dashboard-for-gcp-billing",
  // storageBucket: "",
  // messagingSenderId: "652672182935",
  // appId: "",

  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  databaseURL: "",
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: "",
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId: "",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const googleProvider = new GoogleAuthProvider();
export const microsoftProvider = new OAuthProvider('microsoft.com');
// Initialize Firebase Authentication and get a reference to the service
let authObj = getAuth(app);
authObj.tenantId = process.env.REACT_APP_FIREBASE_TENANTID;
export const auth = authObj;
export const db = getFirestore(app);

export default app;
