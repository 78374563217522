import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import BarChartComponent from "./BarChartComponent";
import BarChartComponent4 from "./BarChartComponent4";
import BarChartComponent5 from "./BarChartComponent5";
import { getDashboardData } from "../../api";
import Loader from "react-js-loader";
import TreeChartComponent from "./TreeChartComponent";
import AreaChartComponent from "./AreaChartComponent";
import LineChartComponent from "./LineChartComponent";

function Chart(props) {
  const { costInfo, selectedProject, totalUersProject } = props;
  const [chart1, setChart1] = useState(null);
  const [chart2, setChart2] = useState(null);
  const [chart3, setChart3] = useState(null);
  const [chart4, setChart4] = useState(null);
  const [chart5, setChart5] = useState(null);
  const [chart6, setChart6] = useState(null);

  useEffect(() => {
    (async () => {
      const {
        chart1,
        chart3,
        chart2,
        chart4,
        chart5,
        chart6,
        costInfo: costData,
      } = await getDashboardData(selectedProject);
      setChart1(chart1);
      setChart2(chart2);
      setChart3(chart3);
      setChart4(chart4);
      setChart5(chart5);
      setChart6(chart6);
      costInfo(costData);
    })();
  }, [selectedProject, totalUersProject]);

  return (
    <>
      <Row>
        <Col md={4} sm={6} xs={12} className="pr-1 pl-1">
          <div className="cust-card">
            <div className="cust-card-header p-3">
              <a className="mb-0">Historical Costs & Forecast</a>
              <p className="text-light-gray mb-0">
                <small>
                  Last 12 months to date by cloud provider with forecast
                </small>
              </p>
            </div>
            <div
              className="cust-card-body p-3"
              style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              {chart1 ? (
                <BarChartComponent info={chart1} />
              ) : (
                <Loader type="spinner-circle" bgColor={"gray"} size={100} />
              )}
            </div>
          </div>
        </Col>
        <Col md={4} sm={6} xs={12} className="pr-1 pl-1">
          <div className="cust-card">
            <div className="cust-card-header p-3">
              <a className="mb-0">This Month vs. Last</a>
              <p className="text-light-gray mb-0">
                <small>
                  On demand usage during this calendar month vs. last month
                </small>
              </p>
            </div>
            <div
              className="cust-card-body p-3"
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {/* <p className="mb-1">Text</p>
            <h5 className="mb-1">Amount</h5>
            <p className="mb-0">More amount</p> */}
              {chart2 ? (
                <AreaChartComponent info={chart2} />
              ) : (
                <Loader type="spinner-circle" bgColor={"gray"} size={100} />
              )}
            </div>
          </div>
        </Col>
        <Col md={4} sm={6} xs={12} className="pr-1 pl-1">
          <div className="cust-card">
            <div className="cust-card-header p-3">
              <a className="mb-0">Services Breakdown</a>
              <p className="text-light-gray mb-0">
                <small>Top 10 on-demand service during last 30 days</small>
              </p>
            </div>
            <div
              className="cust-card-body p-3"
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {chart3 ? (
                <TreeChartComponent info={chart3} />
              ) : (
                <Loader type="spinner-circle" bgColor={"gray"} size={100} />
              )}
            </div>
          </div>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col md={4} sm={6} xs={12} className="pr-1 pl-1">
          <div className="cust-card">
            <div className="cust-card-header p-3">
              <a className="mb-0">Cost by Service</a>
              <p className="text-light-gray mb-0">
                <small>
                  On-demand usage in top 10 services for the last 30 days
                </small>
              </p>
            </div>
            <div
              className="cust-card-body p-3"
              style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              {chart4 ? (
                <BarChartComponent4 info={chart4} />
              ) : (
                <Loader type="spinner-circle" bgColor={"gray"} size={100} />
              )}
            </div>
          </div>
        </Col>
        <Col md={4} sm={6} xs={12} className="pr-1 pl-1">
          <div className="cust-card">
            <div className="cust-card-header p-3">
              <a className="mb-0">Cost by Project/Account</a>
              <p className="text-light-gray mb-0">
                <small>
                  On-demand usage in top 10 projects or accounts for the last 30
                  days
                </small>
              </p>
            </div>
            <div
              className="cust-card-body p-3"
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {/* <p className="mb-1">Text</p>
            <h5 className="mb-1">Amount</h5>
            <p className="mb-0">More amount</p> */}
              {chart5 ? (
                <BarChartComponent5 info={chart5} totalUersProject={totalUersProject}/>
              ) : (
                <Loader type="spinner-circle" bgColor={"gray"} size={100} />
              )}
            </div>
          </div>
        </Col>
        <Col md={4} sm={6} xs={12} className="pr-1 pl-1">
          <div className="cust-card">
            <div className="cust-card-header p-3">
              <a className="mb-0">Trending Services</a>
              <p className="text-light-gray mb-0">
                <small>
                  Trending up or down on-demand services during last 60 days
                </small>
              </p>
            </div>
            <div
              className="cust-card-body p-3"
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {chart6 ? (
                <LineChartComponent info={chart6} />
              ) : (
                <Loader type="spinner-circle" bgColor={"gray"} size={100} />
              )}
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default Chart;
