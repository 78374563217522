import axios from "axios";
import { BASE_URL } from "./URL";

export const getDashboardData = async (selectedProject) => {
  /* const info = await Promise.all([
    axios.get(`${BASE_URL}api/historical-cost/${selectedProject}`),
    axios.get(`${BASE_URL}api/monthly-cost/${selectedProject}`),
    axios.get(`${BASE_URL}api/service-cost/service-breakdown/${selectedProject}`),
    axios.get(`${BASE_URL}api/service-cost/day-wise-cost/${selectedProject}`),
    axios.get(`${BASE_URL}api/project-cost/day-wise-cost/${selectedProject}`),
    axios.get(`${BASE_URL}api/service-cost/trending-service/${selectedProject}`),
    axios.get(`${BASE_URL}api/cost-ratio/${selectedProject}`),
  ]);
  const tmpData = info;

  const dataArray = {
    chart1: tmpData.slice(0, 1)[0]?.data,
    chart2: tmpData.slice(1, 2)[0]?.data,
    chart3: tmpData.slice(2, 3)[0]?.data,
    chart4: tmpData.slice(3, 4)[0]?.data,
    chart5: tmpData.slice(4, 5)[0]?.data,
    chart6: tmpData.slice(5, 6)[0]?.data,
    costInfo: tmpData.slice(6, 7)[0]?.data,
  }; */
  const info = await Promise.all([
    axios.get(`${BASE_URL}api/historical-cost/month-wise/${selectedProject}`),
    axios.get(`${BASE_URL}api/project-cost/day-wise-cost/${selectedProject}`),
    axios.get(`${BASE_URL}api/cost-ratio/${selectedProject}`),
    axios.get(`${BASE_URL}api/service-cost/service-last-days/${selectedProject}`),
  ]);
  const tmpData = info;

  const dataArray = {
    chart1: tmpData.slice(0, 1)[0]?.data.historical_cost,
    chart2: tmpData.slice(0, 1)[0]?.data.monthly_cost,
    chart3: tmpData.slice(3, 4)[0]?.data.service_break_down,
    chart4: tmpData.slice(3, 4)[0]?.data.service_day_wise_cost,
    chart5: tmpData.slice(1, 2)[0]?.data,
    chart6: tmpData.slice(3, 4)[0]?.data.trending_service_cost,
    costInfo: {
      ...tmpData.slice(2, 3)[0]?.data,
      this_month_spend: tmpData.slice(0, 1)[0]?.data.this_month_spend
    }
  };
  return dataArray;
};
