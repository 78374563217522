import React from "react";
// import AnomalyChartComponent from "../../Chart/AnomalyChartComponent";
import { fetchMonth, fetchTime } from "../../Chart/helper";
import { highlightSearchText } from "react-chartjs-library";

const imageInfo = {
  minor: "images/Alert-1.jpg",
  major: "images/Alert-3.jpg",
  critical: "images/Alert-2.jpg",
};

const AnomalyListItem = (props) => {
  const { item, filteredText } = props;
  const anomalyDateTime = item.usage_date;

  /* const displayDate = `${fetchMonth(
    parseInt(anomalyDateTime.split("").splice(5, 2).join(""))
  )} ${parseInt(
    anomalyDateTime.split("").splice(8, 2).join("")
  )}, ${anomalyDateTime.split("").splice(0, 4).join("")}, ${fetchTime(
    anomalyDateTime
  )} `; */
  const displayDate = `${fetchMonth(
    parseInt(anomalyDateTime.split("").splice(5, 2).join(""))
  )} ${parseInt(
    anomalyDateTime.split("").splice(8, 2).join("")
  )}, ${anomalyDateTime.split("").splice(0, 4).join("")}`;

  return (
    <tr>
      <td>{displayDate}</td>
      <td>
        {filteredText && item.description.includes(filteredText) ? (
          <>
            {highlightSearchText({
              item: item,
              key: "description",
              filteredText: filteredText,
              style: { color: "blue" },
            })}
          </>
        ) : (
          <>{item.description}</>
        )}
      </td>

      <td>
        {filteredText && item.name.includes(filteredText) ? (
          <>
            {highlightSearchText({
              item: item,
              key: "name",
              filteredText: filteredText,
              style: { color: "blue" },
            })}
          </>
        ) : (
          <>{item.name}</>
        )}
      </td>
      {/* <td>{item.name}</td> */}

      <td>
        {filteredText && item.billing_account_id.includes(filteredText) ? (
          <>
            {highlightSearchText({
              item: item,
              key: "billing_account_id",
              filteredText: filteredText,
              style: { color: "blue" },
            })}
          </>
        ) : (
          <>{item.billing_account_id}</>
        )}
      </td>
      {/* <td>{item.billing_account_id}</td> */}
      {/* <td
        className="zero-padding"
        style={{
          width: "150px",
          height: "50px",
        }}
        id={`${item.usage_date}`}
      >
        
        <AnomalyChartComponent info={item.anomalies} />
      </td> */}
      <td>
        <img
          className="m-auto severity-img"
          src={imageInfo[item.severity_type]}
          alt="alert"
          title={item.severity_type}
        />
      </td>
      <td>${item.total_cost_after_credits}</td>
    </tr>
  );
};

export default AnomalyListItem;
