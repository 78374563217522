import React, { Component } from "react";
import {
  signInWithEmailAndPassword,
  signInWithPopup,
  setPersistence,
  browserLocalPersistence,
} from "firebase/auth";
import { auth, googleProvider,microsoftProvider } from "../../../firebase";
import jwt_decode from "jwt-decode";
import classnames from "classnames";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setCurrentUser } from "../../../actions/securityActions";
import * as routes from "../../../constants/routes";
import Progress from "react-progress-2";
import { localhost, GOOGLE_CLIENT_ID, createUser } from "../../../actions/index";

class Login extends Component {
  constructor() {
    super();
    this.state = {
      username: "",
      password: "",
      errors: {},
      validLoginInfo: false,
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    //this.signIn();
    
    
    if (Object.keys(this.props.currentUser).length > 0) {
      //console.log("token:::", this.props.ptpuser);
      //this.props.history.push(routes.DASHBOARD);
    }
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.security.user &&
      prevProps.security.user != this.props.security.user
    ) {
      this.props.history.push(routes.DASHBOARD);
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
    setTimeout((e) => {
      if (this.state.username == "" || this.state.password == "") {
        this.setState({ validLoginInfo: false });
      } else {
        this.setState({ validLoginInfo: true });
      }
      // console.log("validLoginInfo:::", this.state.validLoginInfo)
    }, 200);
  }

  onSubmit(e) {
    // this.props.setCurrentUser(token.credential);//
    e.preventDefault();
    let email = this.state.username;
    let password = this.state.password;

    setPersistence(auth, browserLocalPersistence).then(() => {
      signInWithEmailAndPassword(auth, email, password)
        .then((res) => {
          let accessToken = res.user.accessToken;
          sessionStorage.setItem("jwtToken", "Bearer " + accessToken);
          //localStorage.setItem("jwtToken", "Bearer " + accessToken);
          //localStorage.setItem("accessToken", accessToken);
          this.props.setCurrentUser(accessToken);
        })
        .catch((error) => {
          let errors = { errorMessage: "Invalid email or password" };
          this.setState({ errors: errors });
        });
    });
  }
  signInWithGoogle = async () => {
    try {
      setPersistence(auth, browserLocalPersistence).then(() => {
        signInWithPopup(auth, googleProvider)
          .then((res) => {

            let userInfo = {
              'email': res.user.email,
              'uuid': res.user.uid,
              'firstName': res.user.displayName,
              'phoneNumber': res.user.phoneNumber
            }
            this.props.createUser(userInfo)
            let accessToken = res.user.accessToken;
            sessionStorage.setItem("jwtToken", "Bearer " + accessToken);
            localStorage.setItem("jwtToken", "Bearer " + accessToken);
            localStorage.setItem("accessToken", accessToken);
            this.props.setCurrentUser(accessToken);
          })
          .catch((error) => {
            let errors = { errorMessage: "Invalid email or password" };
            this.setState({ errors: errors });
          });
      });
    } catch (err) {
      console.error(err);
    }
  };
  signInWithMicrosoft = async () => {
    try {
      setPersistence(auth, browserLocalPersistence).then(() => {
        signInWithPopup(auth, microsoftProvider)
          .then((res) => {

            let userInfo = {
              'email': res.user.email,
              'uuid': res.user.uid,
              'firstName': res.user.displayName,
              'phoneNumber': res.user.phoneNumber
            }
            this.props.createUser(userInfo)
            let accessToken = res.user.accessToken;
            sessionStorage.setItem("jwtToken", "Bearer " + accessToken);
            localStorage.setItem("jwtToken", "Bearer " + accessToken);
            localStorage.setItem("accessToken", accessToken);
            this.props.setCurrentUser(accessToken);
          })
          .catch((error) => {
            let errors = { errorMessage: "Invalid email or password" };
            this.setState({ errors: errors });
          });
      });
    } catch (err) {
      console.error(err);
    }
  };
  forgotPasswardHandler(){
    this.props.history.push(routes.FORGOTPASSWORD);
  }
  render() {
    const { errors } = this.state;
    const responseGoogle = (response) => {
      let gToken = response.credential;
      let userObj = jwt_decode(gToken);
      //this.setState({user:gToken})
    };

    return (
      <div className="login-bg position-relative">
        <section className="user-section item-center">
          <div className="p-3 p-md-5 m-2 radius-12 shadow text-center bg-white">
            <figure className="brand-ui">
              <img
                src="images/Logo2.png"
                alt="PTP"
                style={{ maxHeight: 191 }}
              />
            </figure>
            <h2 className="font-bold font-20 mb-0">Login</h2>
            <div>
              <div className="error-msg-holder">
                {errors.errorMessage && (
                  <div className="error text-danger mt-2">
                    {errors.errorMessage}
                  </div>
                )}
              </div>
              <form onSubmit={this.onSubmit} className="d-flex flex-column">
                <div className="form-card my-3">
                  <input
                    type="text"
                    autoComplete="off"
                    className={classnames("form-control font-18", {
                      "is-invalid": errors.username,
                    })}
                    placeholder="Email Address"
                    name="username"
                    value={this.state.username}
                    onChange={this.onChange}
                  />
                  {errors.username && (
                    <div className="invalid-feedback">{errors.username}</div>
                  )}
                </div>
                <div className="form-card">
                  <input
                    type="password"
                    autoComplete="off"
                    className={classnames("form-control font-18", {
                      "is-invalid": errors.password,
                    })}
                    placeholder="Password"
                    name="password"
                    value={this.state.password}
                    onChange={this.onChange}
                  />
                  {errors.password && (
                    <div className="invalid-feedback">{errors.password}</div>
                  )}
                </div>
                <a href="javascript:void(0);" onClick={()=>this.forgotPasswardHandler()} className="text-muted mt-2 text-right">Forgot Password?</a>
                <button
                  type="submit"
                  disabled={!this.state.validLoginInfo}
                  className={`btn bg-primary-theme text-white my-3 ${this.state.validLoginInfo ? "active" : ""
                    }`}
                >
                  Sign in
                </button>
              </form>
            </div>
            <div className="google-login d-sm-flex d-block">
              <button
                className="login-provider-button py-3 px-1 w-xs-100 text-center rounded d-flex align-items-center gap-5 mr-sm-1 justify-content-center"
                onClick={this.signInWithGoogle}
              >
                <img
                  src="images/google.png"
                  alt="PTP"
                  style={{ maxHeight: 20 }}
                />
                Sign in with Google
              </button>
              <button
                className="login-provider-button py-3 px-1 w-xs-100 text-center rounded d-flex align-items-center gap-5 ml-sm-1 justify-content-center mt-2 mt-sm-0"
                onClick={this.signInWithMicrosoft}
              >
                <img
                  src="images/Microsoft_icon.svg"
                  alt="PTP"
                  style={{ maxHeight: 20 }}
                />
                Sign in with Microsoft
              </button>
              
            </div>
          </div>
        </section>
      </div>
    );
  }
}

Login.propTypes = {
  login: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  security: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  security: state.security,
  currentUser: state.security.user,
  errors: state.errors,
});

export default connect(mapStateToProps, { setCurrentUser, createUser })(Login);
