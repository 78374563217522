import axios from "axios";
import { BASE_URL } from "./URL";

export const getAnomalyList = async (selectedProject, selectedDaterange) => {
  let data = { 'data': JSON.stringify({selectedDaterange:selectedDaterange}) };
  const info = await Promise.all([
    axios.post(`${BASE_URL}api/service-cost/anomaly-detection/${selectedProject}`,data ),
  ]);
  const tmpData = info;
  const dataArray = {
    anomalyList: tmpData.slice(0, 1)[0]?.data,
  };
  return dataArray;

};
