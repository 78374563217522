
const INITIAL_STATE = {
  applicatonList: []
};
export default function dashboardReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    
    // case FETCH_PROVIDER:
    //   return {
    //     ...state,
    //     providerList: action.payload.overviewUserData,
    //   };
    
    default:
      return state;
  }
}
