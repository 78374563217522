import axios from "axios";
import Progress from "react-progress-2";
import { alertService } from "../components/GlobalComponent/alertService";
export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLECLIENTID;

export const GET_ERRORS = "GET_ERRORS";

//user module
export const FETCH_USERS = "FETCH_USERS";
export const USERS_DISABLED = "USERS_DISABLED";
export const USERS_ENABLED = "USERS_ENABLED";
export const USERS_ADD = "USERS_ADD";
export const USERS_EDIT = "USERS_EDIT"
export const USERS_UPDATED = "USERS_UPDATED"
export const USERS_DELETE = "USERS_DELETE"
export const USERS_RESET = "USERS_RESET"
export const FETCH_USERSPROJECTS = "FETCH_USERSPROJECTS"
export const USERS_SETADMIN="USERS_SETADMIN"
export const USERS_REMOVEADMIN="USERS_REMOVEADMIN"

export function getConfig() {
  const jwtToken = sessionStorage.getItem("jwtToken");
  const config = {
    headers: {
      Authorization: jwtToken,
    },
  };
  return config;
}

// Add a request interceptor
axios.interceptors.request.use(
  (config) => {
    //let url = new URL(config.url);
    //let originURL = url.origin;
    let token = "";
    //alert(originURL+':::'+BASE_URL);
    //if (originURL === BASE_URL) {
    token = sessionStorage.getItem("jwtToken");
    //}

    if (token) {
      config.headers["Authorization"] = token;
    }
    if (!config.loaderHide || config.loaderHide == "false") {
      Progress.show();
    }
    // config.headers['Content-Type'] = 'application/json';
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
// Add a response interceptor
axios.interceptors.response.use(
  (response) => {
    Progress.hide();
    return response;
  },
  function (error) {
    //const originalRequest = error.config;
    Progress.hide();

    if (error.response && error.response.status === 401) {
      // console.log("response intercepter::::");
      // sessionStorage.removeItem("jwtToken");
      sessionStorage.clear();
      localStorage.clear();
      // window.location.reload();
      window.location.href = "/login";
      return Promise.reject(error);
    }

    if (error.response && error.response.status === 500) {
      if (!error.response.data || !error.response.data.errorMessage) {
        alertService.error(
          "Something went wrong. Please contact Administrator."
        );
      }
      return Promise.reject(error);
    } else if (error.response && error.response.status === 400) {
      if (!error.response.data || !error.response.data.errorMessage) {
        alertService.error(
          "Something went wrong. Please contact Administrator."
        );
      }
      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);

export function handleError(error, dispatch) {
  if (error.response) {
    dispatch({
      type: GET_ERRORS,
      payload: error.response.data,
    });
  } else if (error) {
    dispatch({
      type: GET_ERRORS,
      payload: {
        errorMessage: "Something went wrong. Please contact Administrator.",
      },
    });
    // The request was made but no response was received
   
  } else if (error.message) {
    // Something happened in setting up the request that triggered an Error
    console.log("Error", error.message);
  }
}

export function toggleModal(ms) {
  return {
    type: ms.type,
    payload: ms,
  };
}
