import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { TreemapController, TreemapElement } from "chartjs-chart-treemap";
import { Chart } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  TreemapController,
  TreemapElement
);

function colorFromRaw(ctx, colorArray) {
  const companyArray = [];

  if (ctx.type !== "data") {
    return "transparent";
  }

  ctx.chart.data.datasets[0].tree.forEach((datastructure) => {
    companyArray.push(datastructure.description);
  });

  const uniqueCompArray = [...new Set(companyArray)];

  const indexCompany = uniqueCompArray.indexOf(ctx.raw._data.description);

  return `rgba(${colorArray[indexCompany]})`;
}

function splitLabelToFit(label, maxWidth, ctx, value) {
  const words = label.split(" ");
  const lines = [];
  let currentLine = "";
  for (let i = 0; i < words.length; i++) {
    const word = words[i];
    const newLine = currentLine + " " + word;
    const width = ctx.chart.ctx.measureText(newLine).width;
    if (width < maxWidth) {
      currentLine = newLine;
    } else {
      lines.push(currentLine);
      currentLine = word;
    }
  }
  lines.push(currentLine);
  lines.push(`$${value}`);
  return lines;
}

function TreeMapChart(props) {
  const { info, colorArray, borderColor, borderWidth } = props;

  const infoArr = [];
  info.forEach((item) => {
    infoArr.push({
      total_cost: parseFloat(item.total_cost),
      description: item.description,
    });
  });

  const calcDisplayMargin = (val) => {
    if (val > 0.0 && val < 5.0) return 10.0;
    return parseFloat(val);
  };

  const tmpArr = [];
  info.forEach((data) => {
    const tmpObj = {
      ...data,
      display: calcDisplayMargin(data.total_cost),
    };
    tmpArr.push(tmpObj);
  });

  const data = {
    datasets: [
      {
        tree: [...tmpArr],
        backgroundColor: (ctx) => colorFromRaw(ctx, colorArray),
        borderColor: [borderColor],
        key: "display",
        groups: ["description"],
        fontColor: "black",
        fontFamily: "Optima",
        fontSize: 20,
        borderWidth: borderWidth || 0,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    title: {
      display: true,
      text: "Basic treemap sample",
    },
    labels: {
      display: true,
      align: "center",
      position: "center",

      formatter(ctx) {
        if (ctx.type !== "data") {
          return;
        }
        return splitLabelToFit(
          ctx.raw["_data"].label,
          ctx.raw.w * 0.9,
          ctx,
          ctx.raw["_data"].children[0].total_cost
        );
      },
    },
    legend: {
      display: false,
    },

    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: function (item, data) {
            return null;
          },
          label: function (item, data) {
            return `${item.raw._data.children[0].description}`;
          },
          afterLabel: function (item, data) {
            const input = [];
            input.push(`Total cost: $${item.raw._data.children[0].total_cost}`);
            input.push(
              `Total cost after credits: $${item.raw._data.children[0].total_cost_after_credits}`
            );
            return input;
          },
        },
      },
    },
  };

  return (
    <div
      style={{
        // width: "-webkit-fill-available",
        width: "100%",
        height: "250px",
      }}
    >
      <Chart type="treemap" data={data} options={options} />
    </div>
  );
}
export default TreeMapChart;
