export const fetchImage = (direction) => {
  if (direction) {
    const direct = direction.trim().toLowerCase();
    if (direct === "up") {
      return (
        <img
          className="mr-2"
          src="images/up-arrow.svg"
          height={18}
          width={18}
          alt="arrow"
        />
      );
    } else {
      return (
        <img
          className="mr-2"
          src="images/down-arrow.svg"
          height={18}
          width={18}
          alt="arrow"
        />
      );
    }
  }
  return null;
};
