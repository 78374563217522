import jwt_decode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { bindActionCreators } from "redux";
import { SET_CURRENT_USER } from "../actions";
import Dashboard from "./mainTabs/dashboard/Dashboard.js";
import UserList from "./mainTabs/users/UserList.js";
import AnomalyList from "./mainTabs/anomaly/AnomalyList";
import Setting from "./mainTabs/settings/Setting";
import ThresholdSetting from "./mainTabs/settings/ThresholdSetting";
import FortgotPassword from "./mainTabs/authorize/FortgotPassword";
import { toggleModal } from "../actions/index";
import * as routes from "../constants/routes";
import setJWTToken from "../securityUtils/setJWTToken";
import { Alert } from "./GlobalComponent/Alert";
import Login from "./mainTabs/authorize/Login";
import Error404 from "./mainTabs/error/error404";
import HeaderInactive from "./mainTabs/header/header-nav-invalid";
import HeaderActive from "./mainTabs/header/header-nav-valid";
import { PrivateRoute, LoginRoute } from "../routes";

function App() {
  let user = useSelector((state) => state.security.user);
  let validLogin = sessionStorage.getItem("jwtToken");
  //validLogin = "true";
  //setJWTToken(token.user);
  // let Header = null;
  // Header = validLogin ? HeaderActive : HeaderInactive;
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [value, setValue] = useState(0);

  const dispatch = useDispatch();

  const jwtToken = sessionStorage.jwtToken;
  //const jwtToken = localStorage.getItem("jwtToken");

  useEffect(() => {
    if (jwtToken) {
      setJWTToken(jwtToken);
      let decoded = jwt_decode(jwtToken);
      if (Object.values(user).length === 0) {
        dispatch({
          type: SET_CURRENT_USER,
          payload: decoded,
        });
      }
    }
  }, [jwtToken]);

  return (
    <BrowserRouter>
      <div className="flex flex-col w-full min-h-100">
        {/* <Header
          authUser={null}
          version={"Patient"}
          value={value}
          setValue={setValue}
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
        /> */}
        {validLogin ? <Alert></Alert> : null}
        <Switch>
          {/* <Route exact strict path={routes.LANDING} component={Login} />
          <Route exact path="/login" component={Login} />
          <Route
            exact
            path={routes.DASHBOARD}
            render={(props) => (
              <Dashboard
                {...props}
                setValue={setValue}
                setSelectedIndex={setSelectedIndex}
              />
            )}
          />

          <Route
            exact
            path={routes.USERS}
            render={(props) => (
              <>
                <UserList
                  {...props}
                  setValue={setValue}
                  setSelectedIndex={setSelectedIndex}
                />
              </>
            )}
          />

          <Route
            exact
            path={routes.ANOMALY}
            render={(props) => (
              <>
                <AnomalyList
                  {...props}
                  setValue={setValue}
                  setSelectedIndex={setSelectedIndex}
                />
              </>
            )}
          /> */}
          <LoginRoute
            exact
            path={routes.LANDING}
            component={Login}
            value={value}
            setValue={setValue}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
          />
          <LoginRoute
            exact
            path="/login"
            component={Login}
            value={value}
            setValue={setValue}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
          />
          <LoginRoute
            exact
            path="/forgotpassword"
            component={FortgotPassword}
            value={value}
            setValue={setValue}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
          />
          <PrivateRoute
            exact
            path={routes.DASHBOARD}
            value={value}
            setValue={setValue}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
            component={Dashboard}
          />

          <PrivateRoute
            exact
            path={routes.USERS}
            value={value}
            setValue={setValue}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
            component={UserList}
          />

          <PrivateRoute
            exact
            path={routes.ANOMALY}
            value={value}
            setValue={setValue}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
            component={AnomalyList}
          />

          <PrivateRoute
            exact
            path={routes.SETTING}
            value={value}
            setValue={setValue}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
            component={Setting}
          />
          <PrivateRoute
            exact
            path={routes.THRESHOLDSETTING}
            value={value}
            setValue={setValue}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
            component={ThresholdSetting}
          />

          <Route component={Error404} />
        </Switch>
        <div className="mt-auto text-center footer-section py-2">
          Copyright © 2023 by BLATTNER TECHNOLOGIES. All Rights Reserved.
        </div>
      </div>
    </BrowserRouter>
  );
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      toggleModal,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
