//export const SIGN_OUT = '/signup';
export const barColors  = [
"#00CCB7",
"#CC6D00",
"#3B40B5",
"#997366",
"#94E1EB",
"#0090D6",
"#EE7798",
"#C8D148",
"#461254",
"#E4A3F5",
"#3333CC",
"#81CCFE",
"#FFA0B4",
"#C0A1FF",
"#D9DBDF",
"#F4D9FB",
"#8E6E96"
];