/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const AnomalyTableHeader = (props) => {
  const { columnInfo, sortTable, sortIndex } = props;

  return (
    <tr>
      {columnInfo.map((item, index) => {
        let classNameCenterAlign = false;
        if (item.title === "Anomaly" || item.title === "Severity") {
          classNameCenterAlign = true;
        }

        return (
          <th className={classNameCenterAlign ? "text-center" : ""}>
            {item.title}
            {item.sortable ? (
              <a
                href="javascript:void(0)"
                className="ml-1 btn btn-xs btn-link p-0 text-decoration-none shadow-none"
                onClick={() => sortTable(item.dataKey, index)}
              >
                <span style={{ display: "flex" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill={sortIndex[index] === 1 ? "#94E1EB" : "#666"}
                    class="bi bi-arrow-up-short"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z"
                    />
                  </svg>

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill={sortIndex[index] === 0 ? "#94E1EB" : "#666"}
                    class="bi bi-arrow-down-short"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z"
                    />
                  </svg>
                </span>
                {/* )} */}
              </a>
            ) : null}
          </th>
        );
      })}
    </tr>
  );
};

export default AnomalyTableHeader;
