import React, { useState, useEffect, useRef } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from 'react-bootstrap/NavDropdown';
import { auth } from "../../../firebase";
import { signOut } from "firebase/auth";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { userLogout } from "../../../actions/index";
import setJWTToken from "../../../securityUtils/setJWTToken";
import { useHistory, NavLink } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Header(props) {
  const { selectedIndex, setSelectedIndex } = props;
  const wrapperRef = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  //dispatch(fetchContext());

  useEffect(() => {
    const pathName = window.location.pathname;

    if (pathName === "/dashboard") {
      setSelectedIndex(0);
    }
    if (pathName === "/anomaly") {
      setSelectedIndex(1);
    }
    if (pathName === "/users") {
      setSelectedIndex(2);
    }
    if (pathName === "/settings") {
      setSelectedIndex(3);
    }
  }, [window.location.pathname]);

  const [anchorElUser, setAnchorElUser] = useState(null);

  const ref = useRef(null);

  //tabs
  const [value, setValue] = useState(0);
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  // mobile sets not being used yet
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    history.push("/help");
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = "primary-search-account-menu";

  useEffect(() => {
    if (window.location.pathname === "/patients" && value !== 0) {
      setValue(0);
    } else if (window.location.pathname === "/users" && value !== 2) {
      setValue(1);
    } else if (window.location.pathname === "/logs" && value !== 3) {
      setValue(2);
    } else if (window.location.pathname === "/kit" && value !== 1) {
      setValue(3);
    } else if (window.location.pathname === "/help" && value !== 1) {
      setValue(4);
    }
  }, [value]);
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        //alert("You clicked outside of me!");
        closeNav();
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);
  const closeNav = () => {
    setIsNavExpanded(false);
  }
  const user = useSelector((state) => state.security.user);

  const logout = () => {
    signOut(auth)
      .then((res) => {
        userLogout();
      })
      .catch((error) => {
        //console.log(error.message);
      });
  };
  const [mobileMenu, setmobileMenu] = useState(true);
  const toggleNavMenu = () => {
    setmobileMenu(!mobileMenu);
  };
  const toggleMenu = (isExpand) => {
    setIsNavExpanded(isExpand);
  }
  return (
    <React.Fragment>
      <nav className="header-dark header-light z-index-9 position-absolute w-100">
        <div className="mx-auto ps-2 pe-5 py-2">
          <div className="relative flex h-16 items-center justify-between">
            <img className="me-auto" src="images/Logo.png" alt="Your Company" />
            <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary px-2  py-0" onToggle={() => toggleMenu(!isNavExpanded)}
              expanded={isNavExpanded} ref={wrapperRef}>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto mr-4">
                  <NavLink
                    to="/dashboard"
                    eventKey="dashboard"
                    className={
                      selectedIndex === 0
                        ? "navActive text-white"
                        : "text-white"
                    }
                    onClick={() => setSelectedIndex(0)}
                  >
                    Dashboard
                  </NavLink>
                </Nav>
                <Nav className="me-auto mr-4">
                  <NavLink
                    to="/anomaly"
                    eventKey="anomaly"
                    className={
                      selectedIndex === 1
                        ? "navActive text-white"
                        : "text-white"
                    }
                    onClick={() => setSelectedIndex(1)}
                  >
                    Anomaly Detection
                  </NavLink>
                </Nav>
                {user.admin &&
                  <Nav className="me-auto mr-2">
                    <NavLink
                      to="/users"
                      eventKey="users"
                      className={
                        selectedIndex === 2
                          ? "navActive text-white"
                          : "text-white"
                      }
                      onClick={() => setSelectedIndex(2)}
                    >
                      Users
                    </NavLink>
                  </Nav>
                }
                {user.admin &&

                  <NavDropdown title="Settings" id="basic-nav-dropdown" className={
                    (selectedIndex === 31|| selectedIndex === 32)
                      ? "navActive text-white"
                      : "text-white"
                  }>
                    <NavDropdown.Item>
                      <NavLink to="/settings"
                        eventKey="settings"
                        className={
                          selectedIndex === 31
                            ? "navActive text-white w-100 d-block "
                            : "text-white w-100 d-block"
                        }
                        onClick={() => setSelectedIndex(31)}>

                        Projects

                      </NavLink>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      <NavLink
                        to="/thresholdsetting"
                        eventKey="settings"
                        className={
                          selectedIndex === 32
                            ? "navActive text-white w-100 d-block"
                            : "text-white w-100 d-block"
                        }
                        onClick={() => setSelectedIndex(32)}
                      >
                        Threshold Value
                      </NavLink>
                    </NavDropdown.Item> 
                  </NavDropdown>
                }
              </Navbar.Collapse>

            </Navbar>
            <Dropdown>
              <Dropdown.Toggle
                variant=""
                id="dropdown-basic"
                className="p-0 no-after"
              >
                <button
                  type="button"
                  className="relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-0 focus:ring-transprent focus:ring-offset-2 focus:ring-offset-gray-800"
                  id="user-menu-button"
                  aria-expanded="false"
                  aria-haspopup="true"
                >
                  <img
                    className="h-8 w-8 rounded-full"
                    src="images/user-profile.png"
                    alt="profile picture"
                  />
                </button>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item active>{user.email}</Dropdown.Item>
                <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </nav>
    </React.Fragment>
  );
}
