import axios from "axios";

import {
  NODEURL,
  handleError,
  GET_ERRORS,
  FETCH_USERS,
  USERS_DISABLED,
  USERS_ENABLED,
  USERS_ADD,
  USERS_UPDATED,
  USERS_DELETE,
  USERS_RESET,
  FETCH_USERSPROJECTS,
  USERS_SETADMIN,
  USERS_REMOVEADMIN
} from "../actions/index";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
} from "firebase/auth";
import { auth } from "../firebase";
import { BASE_URL } from "../api/URL";
//Admin user
export const AddUser = (payload) => async (dispatch) => {
  try {
    let email = payload.email;
    let password = "Devdigital123#";
    let isAdmin = payload.isAdmin;

    await createUserWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        // Signed in 
        let res = userCredential.user;
        //sendEmailVerification(auth.currentUser);
        sendPasswordResetEmail(auth, email);
        //await sendPasswordResetEmail(email);
        try {
          payload.uuid = res.uid;
          const url = `${BASE_URL}api/users`;
          let newUser;
          newUser = await axios.post(url, payload);
          dispatch({
            type: USERS_ADD,
            payload: newUser,
          });
        } catch (error) {
          handleError(error, dispatch);
        }
        // dispatch({
        //   type: USERS_ADD,
        //   payload: res,
        // });
      })
      .catch((err) => {
        let error = {};
        let errorCode = err.code;
        if (errorCode === "auth/email-already-in-use") {
          error = {
            errorMessage: "Email address already exist."
          }
          dispatch({
            type: GET_ERRORS,
            payload: error,
          });
        } else {
          handleError(err, dispatch);
        }
        // ..
      });

  } catch (error) {
    handleError(error, dispatch);
  }
};

export const fetchUserList =
  (page, perPage, sortColumn = "", sortOrder = "") =>
    async (dispatch) => {
      try {
        const url = `${BASE_URL}api/users`;
        let res;
        res = await axios.get(url);
        dispatch({
          type: FETCH_USERS,
          payload: res.data,
        });
      } catch (error) {
        handleError(error, dispatch);
      }
    };
export const disableUser = (user) => async (dispatch) => {
  try {
    const url = `${BASE_URL}api/users/disable/${user.uid}`;
    let res;
    res = await axios.put(url);
    dispatch({
      type: USERS_DISABLED,
      payload: res.data,
    });
  } catch (error) {
    handleError(error, dispatch);
  }
};
export const enableUser = (user) => async (dispatch) => {
  try {
    const url = `${BASE_URL}api/users/enable/${user.uid}`;
    let res;
    res = await axios.put(url);

    dispatch({
      type: USERS_ENABLED,
      payload: res.data,
    });
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const editUser = (user) => async (dispatch) => {
  try {
    const url = `${BASE_URL}api/users/${user.uid}`;
    let res;
    const data = {
      firstName: user.firstName,
      lastName: user.lastName,
      phoneNumber: user.phoneNumber,
      isAdmin: user.isAdmin,
    }
    res = await axios.put(url, data);
    dispatch({
      type: USERS_UPDATED,
      payload: { message: res.data.message, status: "update_success" }

    });
  } catch (error) {
    handleError(error, dispatch);
  }
}
export const createUser = (payload) =>
  async (dispatch) => {
    try {
      const url = `${BASE_URL}api/users/createUser`;
      let createUser;
      createUser = await axios.post(url, payload);
      // dispatch({
      //   type: USERS_EDIT,
      //   payload: editUser,
      // });
    } catch (error) {
      handleError(error, dispatch);
    }
  };
export const deleteUser =
  (userid) =>
    async (dispatch) => {
      try {
        const url = `${BASE_URL}api/users/${userid}`;
        let res;
        res = await axios.delete(url);
        dispatch({
          type: USERS_DELETE,
          payload: res.data,
        });
      } catch (error) {
        handleError(error, dispatch);
      }
    };

export const sendResetPassword = (email) =>
  async (dispatch) => {
    try {
      sendPasswordResetEmail(auth, email)
      dispatch({
        type: USERS_RESET,
        payload: email,
      });
      setTimeout(() => {
        dispatch({
          type: USERS_RESET,
          payload: '',
        });
      }, 500)

    } catch (error) {
      handleError(error, dispatch);
    }

  }
export const selectedUserProjects =
  (uid) =>
    async (dispatch) => {
      dispatch({
        type: FETCH_USERSPROJECTS,
        payload: 0,
      });
      try {
        const url = `${BASE_URL}api/users/projectlist/${uid}`;
        let res;
        res = await axios.get(url);
        dispatch({
          type: FETCH_USERSPROJECTS,
          payload: res.data,
        });
      } catch (error) {
        handleError(error, dispatch);
      }
    };
export const assignedUserProjects =
  (uid) =>
    async (dispatch) => {
      dispatch({
        type: FETCH_USERSPROJECTS,
        payload: 0,
      });
      try {
        const url = `${BASE_URL}api/users/assignprojectlist/${uid}`;
        let res;
        res = await axios.get(url);
        dispatch({
          type: FETCH_USERSPROJECTS,
          payload: res.data,
        });
      } catch (error) {
        handleError(error, dispatch);
      }
    };

export const markAdmin =
  (user) =>
    async (dispatch) => {
      try {
        const url = `${BASE_URL}api/users/markadmin/${user.uid}`;
        user.admin = true;
        let res;
        res = await axios.put(url, user);
        dispatch({
          type: USERS_SETADMIN,
          payload: res.data,
        });
      } catch (error) {
        handleError(error, dispatch);
      }
    };
export const removeAdmin =
  (user) =>
    async (dispatch) => {
      try {
        const url = `${BASE_URL}api/users/removeadmin/${user.uid}`;
        user.admin = false;
        let res;
        res = await axios.put(url, user);
        dispatch({
          type: USERS_REMOVEADMIN,
          payload: res.data,
        });
      } catch (error) {
        handleError(error, dispatch);
      }
    };

