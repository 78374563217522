import { FETCH_USERS, USERS_DISABLED, USERS_ENABLED, USERS_ADD, USERS_EDIT, USERS_UPDATED, USERS_DELETE, USERS_RESET, USERS_SETADMIN, USERS_REMOVEADMIN } from "../actions/index";

const INITIAL_STATE = {
    userList: [],
    userStatus: {},
    userAdd: {},
    userTogggleAdmin: {},
};
export default function userReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case FETCH_USERS:
            return {
                ...state,
                userList: action.payload,
                userStatus: null
            };
        case USERS_ADD:
            return {
                ...state,
                userAdd: action.payload,
                userStatus: null
            };
        case USERS_EDIT:
            return {
                ...state,
                userEdit: action.payload,
            }
        case USERS_DISABLED:
            return {
                ...state,
                userStatus: action.payload,
            };
        case USERS_ENABLED:
            return {
                ...state,
                userStatus: action.payload
            };
        case USERS_UPDATED:
            return {
                ...state,
                userStatus: action.payload
            }
        case USERS_DELETE:
            return {
                ...state,
                userDelete: action.payload,
            }
        case USERS_RESET:
            return {
                ...state,
                userResetPassword: action.payload,
            }
        case USERS_SETADMIN:
            return {
                ...state,
                userTogggleAdmin: action.payload.data,
            }
        case USERS_REMOVEADMIN:
            return {
                ...state,
                userTogggleAdmin: action.payload.data,
            }
        default:
            return state;
    }
}
