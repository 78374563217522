import { useState, useEffect, useCallback } from "react";
import { Row } from "react-bootstrap";
import ToggleButton from "react-bootstrap/ToggleButton";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import { fetchUserProject, saveUserProjectList } from "../../../actions/index";
import Loader from "react-js-loader";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { alertService } from "./../../GlobalComponent/alertService";
import { highlightSearchText } from "react-chartjs-library";

function ModalPopup(props) {
    const { fetchUserProject, projectList: userProjectList, error, isLoading, saveUserProjectList, isUpdatedSuccess } = props;
    const [show, setShow] = useState(false);
    const [projectList, setProjectList] = useState(null);
    const [displayList, setDisplayList] = useState(null);
    const [updatedProjects, setUpdatedProjects] = useState({});
    const [filterText, setFilterText] = useState("");
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const fetchUserProjectList = useCallback(() => {
        if (show) {
            fetchUserProject(props.uid);
        }
    }, [show, props, fetchUserProject]);
    useEffect(() => {
        if (show) {
            fetchUserProjectList();
        }
    }, [show]);

    useEffect(() => {
        if (userProjectList) {
            const newUserProjectList = userProjectList.map((project, index) => ({
                ...project,
                displayProjectTitle: `${project.projectName} (${project.projectId})`
            }));
            setProjectList(newUserProjectList);
            setDisplayList(newUserProjectList);
        }
    }, [userProjectList]);

    useEffect(() => {
        if (isUpdatedSuccess) {
            alertService.success("Projects list successfully submitted", { autoClose: true });
        }
    }, [isUpdatedSuccess]);

    useEffect(() => {
        if (error) {
            alertService.error(error, { autoClose: true });
        }
    }, [error]);

    const handleFilteredText = (e) => {
        e.preventDefault();
        const val = e.target.value;
        setFilterText(val);
        if (val) {
            const tmpList = displayList.filter((project) => project["displayProjectTitle"].includes(e.target.value));
            setDisplayList(tmpList);
        } else {
            setDisplayList(projectList);
        }
    };

    const filterBySelected = (isSelected) => {
        const tmpDisplayList = projectList.filter((project) => project.access === isSelected);
        if (isSelected === -1) {
            setDisplayList(projectList);
        } else {
            setDisplayList(tmpDisplayList);
        }
    };

    const saveChanges = () => {
        if (Object.values(updatedProjects).length) {
            saveUserProjectList(Object.values(updatedProjects));
            setTimeout(() => {
                onCloseModal();
            }, 250);
        }
    };

    const onCloseModal = () => {
        setProjectList(null);
        setDisplayList(null);
        setUpdatedProjects({});
        setFilterText("");
        handleClose();
    };

    const selectProject = (e, project) => {
        const isChecked = e.target.checked ? 1 : 0;
        let updatedProjectList = updatedProjects;
        updatedProjectList[project.id] = {
            user_id: props.userId,
            project_id: project.id,            
            access: isChecked ? "true" : "false"
        };
        setUpdatedProjects(updatedProjectList);
    }
    return (
        <>
            <Button variant="link" onClick={handleShow} className="px-2">
                <img src="images/list-project-icon.png" className="" alt="project list" title="project list" width={20} />
            </Button>
            <Modal show={show} size="lg" onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Project List</Modal.Title>
                </Modal.Header>
                {isLoading ? (
                    <Loader type="spinner-circle" bgColor={"gray"} size={20} />
                ) : (
                    <>
                        <Modal.Body className="projectlist-popup">
                            <div>
                                <Container>
                                    <Row>
                                        <Col xs={12} md={6}>
                                            <ToggleButtonGroup type="radio" name="options" defaultValue={3}>
                                                <ToggleButton id="tbg-radio-1" value={1} onClick={() => filterBySelected(1)}>
                                                    Selected
                                                </ToggleButton>
                                                <ToggleButton id="tbg-radio-2" value={2} onClick={() => filterBySelected(0)}>
                                                    Unselected
                                                </ToggleButton>
                                                <ToggleButton id="tbg-radio-3" value={3} onClick={() => filterBySelected(-1)}>
                                                    All
                                                </ToggleButton>
                                            </ToggleButtonGroup>
                                        </Col>
                                        <Col>
                                            <Form.Control
                                                type="text"
                                                id="Searchproject"
                                                aria-describedby=""
                                                placeholder="Search"
                                                onChange={handleFilteredText}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12}>
                                            <div className="listofproject">
                                                {displayList &&
                                                    displayList.length > 0 &&
                                                    displayList.map((project, index) => (
                                                            <div
                                                                className="listofproject-container d-flex mb-3"
                                                                id={project.projectId}
                                                                key={project.projectId}
                                                            >
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    id="project-1"
                                                                    label=""
                                                                    checked={parseInt(project.access)}
                                                                    onChange={(e) => {
                                                                        selectProject(e, project)
                                                                        const isChecked = e.target.checked ? 1 : 0;
                                                                        const tmpProj = {
                                                                            ...displayList[index],
                                                                            access: parseInt(isChecked)
                                                                        };
                                                                        displayList[index] = tmpProj;
                                                                        setDisplayList([...displayList]);
                                                                    }}
                                                                />
                                                                <div className="ml-3">
                                                                    <p className="mb-0">
                                                                        <strong>
                                                                            {filterText &&
                                                                                project.displayProjectTitle.includes(filterText) ? (
                                                                                <>
                                                                                    {highlightSearchText({
                                                                                        item: project,
                                                                                        key: "displayProjectTitle",
                                                                                        filteredText: filterText,
                                                                                        style: { color: "blue" }
                                                                                    })}
                                                                                </>
                                                                            ) : (
                                                                                <>{project.displayProjectTitle}</>
                                                                            )}
                                                                        </strong>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                    ))}
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={onCloseModal}>
                                Close
                            </Button>
                            <Button variant="primary" onClick={saveChanges}>
                                Save Changes
                            </Button>
                        </Modal.Footer>
                    </>
                )}
            </Modal>
        </>
    );
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            fetchUserProject,
            saveUserProjectList
        },
        dispatch
    );
}

function mapStateToProps(state) {
    return {
        //provider: state.provider.providerList,
        projectList: state.userProject.projectList,
        error: state.userProject.error,
        isLoading: state.userProject.isLoading,
        isUpdatedSuccess: state.userProject.isUpdatedSuccess
    };
}
// export default ModalPopup;
export default connect(mapStateToProps, mapDispatchToProps)(ModalPopup);
