export const fetchFullMonth = (month) => {
  switch (month) {
    case 1:
      return "January";
    case 2:
      return "February";
    case 3:
      return "March";
    case 4:
      return "April";
    case 5:
      return "May";
    case 6:
      return "June";
    case 7:
      return "July";
    case 8:
      return "August";
    case 9:
      return "September";
    case 10:
      return "October";
    case 11:
      return "November";
    default:
      return "December";
  }
};

export const fetchMonth = (month) => {
  switch (month) {
    case 1:
      return "Jan";
    case 2:
      return "Feb";
    case 3:
      return "Mar";
    case 4:
      return "Apr";
    case 5:
      return "May";
    case 6:
      return "Jun";
    case 7:
      return "Jul";
    case 8:
      return "Aug";
    case 9:
      return "Sept";
    case 10:
      return "Oct";
    case 11:
      return "Nov";
    default:
      return "Dec";
  }
};

export const fetchDay = (day) => {
  return parseInt(day);
};

export const fetchLabel = (date) => {
  var month = fetchMonth(parseInt(date.split("-")[1]));
  var day = fetchDay(date.split("-")[2]);
  return `${month} ${day}`;
};

export const fetchDayNumber = (date) => {
  var month = fetchMonth(parseInt(date.split("-")[1]));
  var day = fetchDay(date.split("-")[2]);
  return `${day}`;
};

export function GFG_sortFunction(a, b) {
  let dateA = new Date(a.usage_date).getTime();
  let dateB = new Date(b.usage_date).getTime();
  return dateA > dateB ? 1 : -1;
}

export function GFG_sortAnomaly(a, b) {
  let dateA = new Date(a.usage_start_time).getTime();
  let dateB = new Date(b.usage_start_time).getTime();
  return dateA > dateB ? 1 : -1;
}

export const fetchChart1XLabel = (monthYrLabel) => {
  let label = monthYrLabel;
  label = label.split("");
  const month = parseInt(label.splice(4, 6).join(""));
  const year = parseInt(label.splice(2, 4).join(""));
  return `${fetchMonth(month)} '${year}`;
};

export const fetchTime = (time) => {
  var dt = new Date(time);
  var hours = dt.getHours(); // gives the value in 24 hours format
  var minutes = dt.getMinutes();
  var AmOrPm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12 || 12;
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  var finalTime = hours + ":" + minutes + " " + AmOrPm;
  return finalTime;
};

export const tableSort = ({ key, list, order }) => {
  if (key.indexOf("date") >= 0) {
    if (order === "asc") {
      list.sort((a, b) => {
        let dateA = new Date(a[key]).getTime();
        let dateB = new Date(b[key]).getTime();
        return dateA > dateB ? 1 : -1;
      });
    }
    if (order === "desc") {
      list.sort((a, b) => {
        let dateA = new Date(a[key]).getTime();
        let dateB = new Date(b[key]).getTime();
        return dateB > dateA ? 1 : -1;
      });
    }
  }
  if (key.indexOf("cost") >= 0) {
    if (order === "asc") {
      list.sort((a, b) => {
        return parseFloat(a[key]) - parseFloat(b[key]);
      });
    }
    if (order === "desc") {
      list.sort((a, b) => {
        return parseFloat(b[key]) - parseFloat(a[key]);
      });
    }
  } else {
    if (order === "asc") {
      list.sort((a, b) => {
        if (a[key] > b[key]) {
          return 1;
        }

        if (a[key] < b[key]) {
          return -1;
        }
        return 0;
      });
    }
    if (order === "desc") {
      list.sort((a, b) => {
        if (b[key] > a[key]) {
          return 1;
        }
        if (b[key] < a[key]) {
          return -1;
        }
        return 0;
      });
    }
  }
};
