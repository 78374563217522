import React from "react";
import TreeMapChart from "./TreeMapChart";

function TreeChartComponent(props) {
  return (
    <div
      style={{
        // width: "-webkit-fill-available",
        width: "100%",
        height: "250px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <TreeMapChart
        {...props}
        colorArray={[
          "200, 209, 72",
          "228, 163, 245",
          "204, 109, 0",
          "59, 64, 181",
          "153, 115, 102",
          "0, 204, 183",
          "0, 144, 214",
          "148, 225, 235",
          "70, 18, 84",
          "238, 119, 152",
        ]}
        borderColor="rgba(252, 251, 244, 1)"
        borderWidth={0}
      />
    </div>
  );
}

export default TreeChartComponent;
