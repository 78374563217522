import { getProjectList, saveProjectList,getBillingTrackerProjectList,updateBillingTrackerProjectList, getThreshold,updateThreshold } from "../api";

export const PROJECT_LIST_INIT = "PROJECT_LIST_INIT";
export const PROJECT_LIST_SUCCESS = "PROJECT_LIST_SUCCESS";
export const PROJECT_LIST_ERROR = "PROJECT_LIST_ERROR";
export const PROJECT_LIST_UPDATED = "PROJECT_LIST_UPDATED";
export const USER_PROJECT_LIST_SUCCESS = "USER_PROJECT_LIST_SUCCESS";
export const THRESHOLD_LIST_SUCCESS = "THRESHOLD_LIST_SUCCESS";
export const THRESHOLD_LIST_ERROR = "THRESHOLD_LIST_ERROR";
export const THRESHOLD_LIST_UPDATED = "THRESHOLD_LIST_UPDATED";
export const FETCH_USERSLIST="FETCH_USERSLIST";

export const fetchBillingTrackerProject = (info) => async (dispatch) => {
    try {
        dispatch({
            type: PROJECT_LIST_INIT
        });
        const res = await getBillingTrackerProjectList(info);        
        if (res.status === 200) {
            dispatch({
                type: PROJECT_LIST_SUCCESS,
                payload: res.data
            });
        }
    } catch (err) {
        if (err?.response?.data) {
            dispatch({
                type: PROJECT_LIST_ERROR,
                error: err.response.data
            });
        }
    }
};

export const fetchUserProject = (info) => async (dispatch) => {
    try {
        dispatch({
            type: PROJECT_LIST_INIT
        });
        const res = await getProjectList(info);
        if (res.status === 200) {
            dispatch({
                type: PROJECT_LIST_SUCCESS,
                payload: res.data.data
            });
        }
    } catch (err) {
        if (err?.response?.response.data) {
            dispatch({
                type: PROJECT_LIST_ERROR,
                error: err.response.data
            });
        }
    }
};

export const saveUserProjectList = (info) => async (dispatch) => {
    try {
        const res = await saveProjectList(info);
        if (res.status === 200) {
            dispatch({
                type: PROJECT_LIST_UPDATED,
                payload: res.data.message
            });  
            dispatch({
                type: FETCH_USERSLIST,
                payload: res.data,
              });         
        }
    } catch (err) {
        if (err?.response?.data) {
            dispatch({
                type: PROJECT_LIST_ERROR,
                error: err.response.data
            });
        }
    }
};
export const saveBillingTrackerProjectList = (info) => async (dispatch) => {
    try {
        const res = await updateBillingTrackerProjectList(info);
        if (res.status === 200) {
            dispatch({
                type: PROJECT_LIST_UPDATED,
                payload: res.data.message
            });
            const fetchListRes = await getBillingTrackerProjectList(info[0].user_id);
            if (fetchListRes.status === 200) {
                dispatch({
                    type: PROJECT_LIST_SUCCESS,
                    payload: fetchListRes.data
                });
            }
        }
    } catch (err) {
        if (err?.response?.data) {
            dispatch({
                type: PROJECT_LIST_ERROR,
                error: err.response.data
            });
        }
    }
};
export const fetchThreshold = (info) => async (dispatch) => {
    try {
        const res = await getThreshold(info);
        if (res.status === 200) {
            dispatch({
                type: THRESHOLD_LIST_SUCCESS,
                payload: res.data
            });
            
        }
    } catch (err) {
        if (err?.response?.data) {
            dispatch({
                type: THRESHOLD_LIST_ERROR,
                error: err.response.data
            });
        }
    }
};

export const saveThreshold = (info) => async (dispatch) => {
    try {
        const res = await updateThreshold(info);
        if (res.status === 200) {
            dispatch({
                type: THRESHOLD_LIST_UPDATED,
                payload: res.data.message
            });
            const fetchListRes = await getThreshold();
            if (fetchListRes.status === 200) {
                dispatch({
                    type: THRESHOLD_LIST_SUCCESS,
                    payload: fetchListRes.data
                });
            }
        }
    } catch (err) {
        if (err?.response?.data) {
            dispatch({
                type: PROJECT_LIST_ERROR,
                error: err.response.data
            });
        }
    }
};
