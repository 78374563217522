import React, { useEffect, useState } from "react";
import { AreaChart } from "react-chartjs-library";
import { Line } from 'react-chartjs-2';
import { barColors } from "../../constants/barColors";
import { fetchDayNumber, GFG_sortFunction,fetchFullMonth,fetchMonth } from "./helper";
import moment from 'moment'

const AreaChartComponent = (props) => {
  const [dataSets, setDataSets] = useState();
  const [labelArray, setLabelArray] = useState();
  const setYScaleCallback = (val, index) => {
    return `$${val}`;
  };

  const setXScaleCallback = (e, val, index) => {
    return index % 2 === 0 ? e.getLabelForValue(val) : "";
  };
  
  const info1 = props?.info;
  useEffect(() => {
    const sortedArray = info1.sort(GFG_sortFunction);
    let labelArr = [];
    let dateArr = [];
    sortedArray.forEach((element, index1) => {
      //lengthArr.push(element.name.length);
      const label = fetchDayNumber(element.usage_date);
      labelArr.push(label);
      dateArr.push(element.usage_date);
    });
    const groupByMonth = sortedArray.reduce((group, chart) => {
      const { month } = chart;
      group[month] = group[month] ?? [];
      group[month].push(chart);
      return group;
    }, {});

    let dataValues = [];
    let graphColor = ['#F4D9FB','#8E6E96']
    Object.keys(groupByMonth).forEach((month, index) => {
      let data = [];
      let label = [];
      //data[project]=[]
      groupByMonth[month].forEach(val => {
        data.push(val.total_cost);        
      })
      dataValues.push({
        fill: true,
        label: fetchMonth(Number(moment(month,"YYYYM").format("M"))),
        data: data       
      },)
    });
    labelArr = labelArr.filter((item,
      index) => labelArr.indexOf(item) === index);
      
    setLabelArray(labelArr);
    
    setDataSets(dataValues);
  }, [props.info]);
  const decimals = 0;
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },    
      tooltip: {
        callbacks: {
          label: function (tooltipItem, data) {
            return tooltipItem.dataset.label + ": $" + (tooltipItem.formattedValue)
          },          
        }
      }  
    },
    scales: {        
      y: {        
          ticks: {
              callback: function(value, index, values) {                 
                  return '$' + value.toFixed(decimals);
              }
          }
      },
  },  
  };
  const labels = labelArray?labelArray:[];
  const data = {
    labels,
    datasets: dataSets?dataSets:[]
  };

  return (
    <div
      style={{
        // width: "-webkit-fill-available",
        width: "100%",
        height: "250px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Line options={options} data={data} />
    </div>
  );
};

export default AreaChartComponent;
