import { PROJECT_LIST_INIT, PROJECT_LIST_SUCCESS, PROJECT_LIST_ERROR, PROJECT_LIST_UPDATED, FETCH_USERSPROJECTS, USER_PROJECT_LIST_SUCCESS, THRESHOLD_LIST_SUCCESS, THRESHOLD_LIST_ERROR,THRESHOLD_LIST_UPDATED,FETCH_USERSLIST } from "../actions";

const initialState = {
    projectList: null,
    thresholdList: null,
    isLoading: false,
    error: null,
    isUpdatedSuccess: null,
    selectedProjects: '',
    isThresholdUpdatedSuccess: null,
};

export default function appReducer(state = initialState, action) {
    switch (action.type) {
        case PROJECT_LIST_INIT:
            return {
                ...state,
                projectList: null,
                isLoading: true,
                error: null,
                isUpdatedSuccess: null
            };
        case PROJECT_LIST_SUCCESS: {
            const projList = action.payload;
            const tmpProjList = [];
            projList.forEach((element) => {
                tmpProjList.push({ ...element, access: element.access === 1 || element.access === "true" ? 1 : 0 });
            });
            return {
                ...state,
                projectList: tmpProjList,
                isLoading: false,
                error: null,
                isUpdatedSuccess: null
            };
        }

        case PROJECT_LIST_ERROR:
            return {
                ...state,
                projectList: null,
                isLoading: false,
                error: action.error,
                isUpdatedSuccess: null
            };

        case PROJECT_LIST_UPDATED:{           
            return {
                ...state,
                error: null,
                isUpdatedSuccess: action.payload
            };
        }
        case FETCH_USERSPROJECTS:
            return {
                ...state,
                error: null,
                selectedProjects: action.payload.data
            };
        case FETCH_USERSLIST:
            return {
                ...state,
                error: null,
                isUpdatedSuccess: null
            };

        case USER_PROJECT_LIST_SUCCESS: {
            const projList = action.payload.data;
            const tmpProjList = [];
            projList.forEach((element) => {
                tmpProjList.push({ ...element, access: element.access === 1 || element.access === "true" ? 1 : 0 });
            });
            return {
                ...state,
                projectList: tmpProjList,
                isLoading: false,
                error: null,
                isUpdatedSuccess: null
            };
        }
        case THRESHOLD_LIST_SUCCESS: {
            const thresholdList = action.payload;
            
            return {
                ...state,
                thresholdList: thresholdList,
                isLoading: false,
                isThresholdUpdatedSuccess: null
            };
        }
        case THRESHOLD_LIST_ERROR:
            return {
                ...state,
                thresholdList: null,
                isLoading: false,
                error: action.error
            };
        case THRESHOLD_LIST_UPDATED: return {
            ...state,
            error: null,
            isThresholdUpdatedSuccess: action.payload
        };

        default:
            return state;
    }
}
