import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchBillingTrackerProject, saveBillingTrackerProjectList } from '../../../actions/index';
import { Row } from "react-bootstrap";
import ToggleButton from "react-bootstrap/ToggleButton";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import Button from 'react-bootstrap/Button';
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { alertService } from './../../GlobalComponent/alertService';
import * as routes from '../../../constants/routes';
import { highlightSearchText } from "react-chartjs-library";


class Setting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            projectList: '',
            displayList: '',
            filterText: '',
            updatedProjects: {},
        };
    }

    componentDidMount() {
        setTimeout(() => {
            let currentUser = this.props.currentUser;
            if (currentUser === '' || Object.keys(currentUser).length === 0) {
                window.location.href = '/';
                return false;
            } else {
                this.setState({ currentUser: currentUser });
            }
            if (!currentUser.admin) {
                this.props.history.push(routes.DASHBOARD);
            }
            this.props.fetchBillingTrackerProject();
        }, 500);
    }

    componentDidUpdate(prevProps) {
        if (this.props.projectList && prevProps.projectList !== this.props.projectList) {
            this.setState({ projectList: this.props.projectList, displayList: this.props.projectList });
        }
        if (this.props.isUpdatedSuccess && prevProps.isUpdatedSuccess !== this.props.isUpdatedSuccess) {
            alertService.success("Projects list successfully submitted", { autoClose: true });
        }
    }

    handleFilteredText = (e) => {
        e.preventDefault();
        const val = e.target.value;
        this.setState({ filterText: val })
        if (val) {
            const tmpList = this.state.displayList.filter((project) => project["project_name"].includes(e.target.value));
            this.setState({ displayList: tmpList });

        } else {
            this.setState({ displayList: this.state.projectList });
        }
    };

    filterBySelected = (isSelected) => {
        const tmpDisplayList = this.state.projectList.filter((project) => project.access === isSelected);
        if (isSelected === -1) {
            this.setState({ displayList: this.state.projectList });
        } else {
            this.setState({ displayList: tmpDisplayList })
        }
    };

    saveChanges = () => {
        if (Object.values(this.state.updatedProjects).length) {
            this.props.saveBillingTrackerProjectList(Object.values(this.state.updatedProjects));
        }
    };

    selectProject = (e, project, index) => {
        const isChecked = e.target.checked ? 1 : 0;
        let displayList = this.state.displayList;
        displayList[index].access = isChecked;
        this.setState({ displayList: displayList });
        let updatedProjectList = this.state.updatedProjects;
        updatedProjectList[project.project_id] = {
            user_id: this.state.currentUser.user_id,
            project_id: project.project_id,
            project_name: project.project_name,
            is_selected: isChecked ? "true" : "false"
        };
        this.setState({ updatedProjects: updatedProjectList });
    }

    render() {
        return (
            <>
                <div className="pt-76 bg-dark-theme bg-light-theme grow-1 flex flex-col">
                    <div class="p-4 projectlist-popup">
                        <Container>
                            <div className="d-flex flex-wrap align-items-center mb-3">
                                <h2 className="">Projects</h2>
                            </div>
                            <Row>
                                <Col xs={12} md={6}>
                                    <ToggleButtonGroup type="radio" name="options" defaultValue={3}>
                                        <ToggleButton id="tbg-radio-1" value={1} onClick={() => this.filterBySelected(1)}>
                                            Selected
                                        </ToggleButton>
                                        <ToggleButton id="tbg-radio-2" value={2} onClick={() => this.filterBySelected(0)}>
                                            Unselected
                                        </ToggleButton>
                                        <ToggleButton id="tbg-radio-3" value={3} onClick={() => this.filterBySelected(-1)}>
                                            All
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </Col>
                                <Col>
                                    <Form.Control
                                        type="text"
                                        id="Searchproject"
                                        aria-describedby=""
                                        placeholder="Search"
                                        onChange={this.handleFilteredText}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12}>
                                    <div className="listofproject listallprojects">
                                        {this.state.displayList &&
                                            this.state.displayList.length > 0 &&
                                            this.state.displayList.map((project, index) => (
                                                <>
                                                    <div
                                                        className="listofproject-container d-flex"
                                                        id={project.project_id}
                                                    >
                                                        <Form.Check
                                                            type="checkbox"
                                                            id="project-1"
                                                            label=""
                                                            checked={parseInt(project.access)}
                                                            onChange={(e) => {
                                                                this.selectProject(e, project, index)

                                                            }}

                                                        />
                                                        <div className="ml-3">
                                                            <p className="mb-0">
                                                                <strong>
                                                                    {/* {project.project_name} */}
                                                                    {this.state.filterText &&
                                                                        project.project_name.includes(this.state.filterText) ? (
                                                                        <>
                                                                            {highlightSearchText({
                                                                                item: project,
                                                                                key: "project_name",
                                                                                filteredText: this.state.filterText,
                                                                                style: { color: "blue" }
                                                                            })}
                                                                        </>
                                                                    ) : (
                                                                        <>{project.project_name} </>
                                                                    )}
                                                                </strong>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </>
                                            ))}
                                    </div>
                                </Col>
                            </Row>
                            <Button variant="primary" onClick={this.saveChanges} className='mt-3'>
                                Save Changes
                            </Button>
                        </Container>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        //provider: state.provider.providerList,
        currentUser: state.security.user,
        projectList: state.userProject.projectList,
        isUpdatedSuccess: state.userProject.isUpdatedSuccess
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            fetchBillingTrackerProject,
            saveBillingTrackerProjectList
        },
        dispatch
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(Setting);
