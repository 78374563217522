import React from "react";
import HeaderInactive from "../components/mainTabs/header/header-nav-invalid";
import HeaderActive from "../components/mainTabs/header/header-nav-valid";

const CommonHeader = (props) => {
  const { validLogin, value, setValue, selectedIndex, setSelectedIndex } =
    props;
  let Header = null;
  Header = validLogin ? HeaderActive : HeaderInactive;
  return (
    <Header
      authUser={null}
      version={"Patient"}
      value={value}
      setValue={setValue}
      selectedIndex={selectedIndex}
      setSelectedIndex={setSelectedIndex}
    />
  );
};

export default CommonHeader;
