import React from "react";
import { Redirect, Route } from "react-router-dom";
import CommonHeader from "./CommonHeader";

function PrivateRoute({ component: Component, ...rest }) {
  const tokenPresent = sessionStorage.getItem("jwtToken");
  //const tokenPresent = localStorage.getItem("jwtToken");

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!tokenPresent) {
          // not logged in so redirect to login page with the return url
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        }

        // authorized so return component
        return (
          <>
            <CommonHeader
              validLogin={tokenPresent}
              authUser={null}
              version={"Patient"}
              value={rest.value}
              setValue={rest.setValue}
              selectedIndex={rest.selectedIndex}
              setSelectedIndex={rest.setSelectedIndex}
            />
            <Component {...props} />
          </>
        );
      }}
    />
  );
}

export default PrivateRoute;
