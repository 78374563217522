import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchUserList, disableUser, enableUser, deleteUser, sendResetPassword, markAdmin, removeAdmin } from '../../../actions/index';
import DataTable from '../../GlobalComponent/Datatable';
import Button from 'react-bootstrap/Button';
import Pagination from '../../GlobalComponent/Pagination';
import { alertService } from './../../GlobalComponent/alertService';
import AddEditUser from './AddEditUser';
import * as routes from '../../../constants/routes';
import ConfirmationDeleteModal from '../../GlobalComponent/ConfirmationDeleteModal';
import ModalPopup from './ModalPopup';

class UserList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            perPage: 10,
            totalItems: 0,
            sortColumn: '',
            sortOrder: '',
            totalPages: 0,
            AddEditUserModal: false,
            EditElement: null,
            EditUser: {},
            mode: 'Add',
            userId: '',
            users: {},
            sortInfo: {
                sortBy: 'firstName',
                sortOrder: 'asc'
            },
            pageInfo: {
                page: 1,
                perPage: 100,
                total: 100,
                totalPages: '2'
            },
            currentUser: '',
            modalType: '',
            selected_user_id: ''
        };
    }
    componentWillMount() {
        const tableColumns = [
            {
                name: 'First Name',
                selector: 'firstName',
                sortable: true
            },
            {
                name: 'Last Name',
                selector: 'lastName',
                sortable: false
            },

            // {
            //     name: 'UID',
            //     selector: 'uid',
            //     sortable: false
            // },

            {
                name: 'Email',
                selector: 'email',
                sortable: false
            },
            {
                name: 'Phone Number',
                selector: 'phoneNumber',
                headerClassName: 'w-180px',
                sortable: false
            },
            {
                name: 'Action',
                selector: 'action',
                headerClassName: 'text-right w-600px',
                columnClassName: 'text-right',
                cell: (row) => {
                    return (
                        <>
                            {row.uid !== this.state.currentUser.user_id && (
                                <>
                                    <Button variant="link" onClick={() => this.changeStatus(row)}>
                                        {row.disabled ? 'Re-Enable User' : 'Disable User'}
                                    </Button>
                                    |
                                    <Button variant="link" onClick={() => this.toggleAdmin(row)}>
                                        {row.isAdmin ? 'Remove From Admin' : 'Mark As Admin'}
                                    </Button>
                                    |
                                </>

                            )}
                            <Button variant="link" onClick={() => this.sendResetPassword(row)}>
                                Reset Password
                            </Button>
                            |
                            <div className="d-inline-block" style={{ minWidth: '40px' }}>
                                <Button className='px-2' variant="link" onClick={() => this.editUser(row)}>
                                    <img src="images/edit_black.png" className="" alt="send" width={20} />
                                </Button>
                            </div>
                            {/* <Button variant="link" onClick={() => this.handleShow()}>
                            <img src="images/list-project-icon.png" className="" alt="send" width={20} />
                        </Button> */}
                            <div className="d-inline-block" style={{ minWidth: '40px' }}>
                                <ModalPopup {...row} />
                            </div>
                            <div className="d-inline-block" style={{ minWidth: '40px' }}>
                                {row.uid !== this.state.currentUser.user_id && (
                                    <Button variant="link" onClick={() => this.deleteUserPopup(row.uid)} className='px-2'>
                                        <img src="images/delete_red.svg" className="" alt="send" width={20} />
                                    </Button>
                                )}
                            </div>
                        </>
                    );
                }
            }
        ];
        this.setState({
            columns: tableColumns
        });
    }
    componentDidMount() {
        setTimeout(() => {
            let currentUser = this.props.currentUser;
            if (currentUser === '' || Object.keys(currentUser).length === 0) {
                window.location.href = '/';
                return false;
            } else {
                this.setState({ currentUser: currentUser });
            }
            if (!currentUser.admin) {
                this.props.history.push(routes.DASHBOARD);
            }
            //console.log("authProvider:::",authProvider);
            this.props.fetchUserList();
        }, 500);
    }
    componentDidUpdate(prevProps) {
        if (this.props.user && prevProps.user !== this.props.user) {
            this.setState({ users: this.props.user });

        }
        if (this.props.userStatus && prevProps.userStatus !== this.props.userStatus) {
            if (this.state.mode === 'Edit') {
                this.setState({ AddEditUserModal: false, mode: 'Add', EditUser: {} });
            }
            if (this.props.error) {
                alertService.error(this.props.error, { autoClose: true });
            }
            this.props.fetchUserList();
            alertService.success(this.props.userStatus.message, { autoClose: true });
        }
        if (this.props.userDelete && this.props.userDelete !== prevProps.userDelete) {
            alertService.success(this.props.userDelete.message, { autoClose: true });
            this.setState({ modalType: false });
            this.props.fetchUserList();
        }
        if (this.props.userResetPassword && this.props.userResetPassword !== prevProps.userResetPassword) {
            alertService.success('Reset Password link sent successfully on ' + this.props.userResetPassword, { autoClose: true });
            this.setState({ modalType: false });
            this.props.fetchUserList();
        }
        if (this.props.userTogggleAdmin && this.props.userTogggleAdmin !== prevProps.userTogggleAdmin) {
            if (this.props.userTogggleAdmin.customClaims.admin) {
                alertService.success('User Mark as Admin successfully.', { autoClose: true });
            } else {
                alertService.success('User Removed From Admin successfully.', { autoClose: true });
            }
            this.props.fetchUserList();
        }
    }
    changeStatus(user) {
        if (user.disabled) {
            this.props.enableUser(user);
        } else {
            this.props.disableUser(user);
        }
    }
    toggleAdmin(user) {
        if (!user.isAdmin) {
            this.props.markAdmin(user);
        } else {
            this.props.removeAdmin(user);
        }
    }
    async onSortColumn(sortInfo) {
        this.setState({
            sortInfo: sortInfo
        });

        //sortInfo.sortOrder

        let users = this.state.users;
        users.sort((a, b) => {
            const name1 = a.firstName.toLowerCase(), name2 = b.firstName.toLowerCase();
            if (sortInfo.sortOrder === 'asc') {
                return name1 === name2 ? 0 : name1 < name2 ? -1 : 1;
            } else {
                return name1 === name2 ? 0 : name1 > name2 ? -1 : 1;
            }
        });
        this.setState({
            users: users
        });
    }

    editUser(user) {
        this.setState({ AddEditUserModal: true, mode: 'Edit', EditUser: { ...user } });
        // this.props.editUser(user);
    }

    sendResetPassword(user) {
        this.props.sendResetPassword(user.email);
    }
    deleteUserPopup(id, type = 'DELETE') {
        this.setState({
            modalType: type,
            selected_user_id: id
        });
    }
    handleOpen = () => this.setState({ AddEditUserModal: true, mode: 'Add' });
    handleClose = () => this.setState({ AddEditUserModal: false });
    onChangePage(pageOfItems) {
        let page_info = this.state.pageInfo;
        page_info.page = pageOfItems.currentPage;
        page_info.perPage = pageOfItems.pageSize;
        this.setState({ pageInfo: page_info });
        //this.fetchRecords();
    }

    onChangePerPage(pageInfo) {
        let page_info = this.state.pageInfo;
        page_info = pageInfo;
        //console.log(page_info);

        this.setState({ pageInfo: page_info });
        //this.fetchRecords();
    }
    closeModal() {
        this.setState({
            modalType: ''
        });
    }
    deleteRecord(id) {
        this.props.deleteUser(id);
    }
    render() {
        const NoResultsOverlay = () => {
            return <div className="no-result">0 results found</div>;
        };
        return (
            <>
                <div className="pt-76 bg-dark-theme bg-light-theme grow-1 flex flex-col">
                    <div className="p-4">
                        <div className="d-flex flex-wrap align-items-center mb-3">
                            <h2 className="">Users</h2>
                            <button
                                className="ml-auto bg-primary-theme pointer py-2 px-4 border-0 rounded-14 text-white font-figtree font-700 font-18"
                                variant="contained"
                                onClick={() => this.handleOpen()}
                            >
                                Invite User
                            </button>
                        </div>

                        {this.state.AddEditUserModal && (
                            <AddEditUser
                                mode={this.state.mode}
                                EditElement={this.state.EditElement}
                                EditUser={this.state.EditUser}
                                handleClose={this.handleClose}
                                AddEditUserModal={this.state.AddEditUserModal}
                                page={this.state.page}
                                perPage={this.state.perPage}
                                sortColumn={this.state.sortColumn}
                                sortOrder={this.state.sortOrder}
                                userId={this.state.userId}
                            />
                        )}
                        <div className="col-12">
                            <div className="row">
                                {this.state.users !== '' && (
                                    <DataTable
                                        title="Users"
                                        columns={this.state.columns}
                                        data={this.state.users}
                                        sortInfo={this.state.sortInfo}
                                        onSortColumn={this.onSortColumn.bind(this)}
                                        colSpan="10"
                                        pageInfo={this.state.pageInfo}
                                    />
                                )}
                                <Pagination
                                    items={this.state.users}
                                    pageInfo={this.state.pageInfo}
                                    onChangePage={this.onChangePage.bind(this)}
                                    onChangePerPage={this.onChangePerPage.bind(this)}
                                ></Pagination>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.modalType == 'DELETE' ? (
                    <ConfirmationDeleteModal
                        confirmeDelete={(id) => this.deleteRecord(id)}
                        closePopup={this.closeModal.bind(this)}
                        showDeletePopup={this.state.modalType == 'DELETE' ? true : false}
                        recordId={this.state.selected_user_id}
                    ></ConfirmationDeleteModal>
                ) : null}
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        //provider: state.provider.providerList,
        currentUser: state.security.user,
        user: state.user.userList,
        userStatus: state.user.userStatus,
        error: state.errors.errorMessage,
        userDelete: state.user.userDelete,
        userResetPassword: state.user.userResetPassword,
        userTogggleAdmin: state.user.userTogggleAdmin
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            fetchUserList,
            disableUser,
            enableUser,
            deleteUser,
            sendResetPassword,
            markAdmin,
            removeAdmin,
        },
        dispatch
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(UserList);
