import React, { useEffect, useState } from "react";
import { BarChart } from "react-chartjs-library";
import { GFG_sortFunction, fetchChart1XLabel } from "./helper";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Bar } from 'react-chartjs-2';
import { barColors } from "../../constants/barColors";

const BarChartComponent = (props) => {
    const [data, setData] = useState([]);
    const [dataSets, setDataSets] = useState();
    const [labelArray, setLabelArray] = useState();
    const [forcastData, setForcastData] = useState(null);
    const datakey = ["total_cost_after_credits", "total_cost"];

    useEffect(() => {
        let dataArr = props?.info.sort(GFG_sortFunction);
        // console.log("dataArr ===> ", dataArr);
        setData(dataArr);
        let dataValues = [];
        let labelArr = [];
        let totalCost = [];
        let totalCostAfterCredits = [];
        
        dataArr = dataArr.sort((a,b)=> a.month-b.month);
        dataArr.forEach(ele => {
            labelArr.push(fetchChart1XLabel(ele.month));
            totalCost.push(ele.total_cost - ele.total_cost_after_credits);
            totalCostAfterCredits.push(ele.total_cost_after_credits);
        });
        setLabelArray(labelArr);

        dataValues.push({
            label: 'Total Cost After Credits',
            data: totalCostAfterCredits,
            backgroundColor: barColors[0]
        },)
        dataValues.push({
            label: 'Total Cost ',
            data: totalCost,
            backgroundColor: barColors[1]
        },)
        //console.log("dataValues:::", dataValues);
        setDataSets(dataValues);
        setForcastData(dataValues);
    }, [props.info]);

    const setDataLabelCallback = (value, context, isStacked) => {
        if (value > 0) {
            if (!isStacked) {
                return `$${value}`;
            }
            return context.datasetIndex === 1 ? `$${data[context.dataIndex]?.[datakey[1]]}` : "";
        }
        return "";
    };

    const setYScaleCallback = (val, index) => {
        return `$${val}`;
    };

    const setXScaleCallback = (e, val, index) => {
        return fetchChart1XLabel(e.getLabelForValue(val));
    };

    /* If implementation not provided, takes the default tooltip label */

    const tooltiplabelCallback = (dataItem) => {
        if (dataItem) {
            const item = dataItem;
            const { datasetIndex, dataIndex } = item;
            let key = datasetIndex === 0 ? datakey[0] : datakey[1];
            const item2 = data[dataIndex]?.[key];
            return `${item.dataset.label}: $${item2}`;
        }
    };

    const tooltiptitleCallback = (item) => {
        return fetchChart1XLabel(item[0].label);
    };
    const decimals = 0;
    const options = {
        plugins: {
            legend: {
                display: false,
                labels: {
                    filter: (legendItem, data) => data.datasets[0].data[legendItem.index] != 0
                }
            },
            datalabels: {
                display: true,
            },
            tooltip: {
                filter: tooltipItem => tooltipItem.dataset.data[tooltipItem.dataIndex] > 0,
                callbacks: {
                    label: function (tooltipItem, data) {
                        if (tooltipItem.dataset.label.trim() == 'Total Cost') {
                            return 'Total Cost: $'+(tooltipItem.parsed._stacks.y[0]+tooltipItem.parsed._stacks.y[1]).toFixed(2)
                        } else {
                            return tooltipItem.dataset.label + ": $" + (tooltipItem.formattedValue)
                        }
                    },
                    footer: function (items) {

                        //return 'Total: $' + (items.reduce((a, b) => a + b.parsed.y, 0)).toFixed(2)
                    }
                }
            }
        },
        responsive: true,
        // interaction: {
        //     mode: 'index',
        //   },
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
                ticks: {
                    callback: function(value, index, values) {
                        return '$' + value.toFixed(decimals);
                    }
                }
            },
        },
    };
    const labels = labelArray;
    const dataVal = {
        labels,
        datasets: dataSets
    };    
    //const labels =[data[0].name, data[0].name]
    return (
        <div
            // className="custom=height"
            id="bar-chart"
            style={{
                // width: "-webkit-fill-available",
                width: "100%",
                height: "250px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}
        >

            {forcastData !== null && forcastData.length > 0 && (
                //      <BarChart
                //     info={data}
                //     isStacked={true}
                //     colorArray={["rgba(70, 18, 84, 1)", "rgba(228, 163, 245, 1)"]}
                //     dataArray={
                //         data && [
                //             data.map((data) => data.total_cost_after_credits),
                //             data.map((data) => data.total_cost - data.total_cost_after_credits)
                //         ]
                //     }
                //     labelArray={data && data.length > 0 && [data[0].name, data[0].name]}
                //     optionParam={{ responsive: true }}
                //     setDataLabelCallback={setDataLabelCallback}
                //     setXScaleCallback={setXScaleCallback}
                //     setYScaleCallback={setYScaleCallback}
                //     tooltiptitleCallback={tooltiptitleCallback}
                //     tooltiplabelCallback={tooltiplabelCallback}
                //     xtickfont={{ size: 10, color: null }}
                //     ytickfont={{ size: null, color: null }}
                // /> 
                <Bar options={options} data={dataVal} />
            )}
        </div>
    );
};

export default BarChartComponent;
