import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchUserList } from "../../../actions/index";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
// import { TextField } from "utility-reactlibrary-2";
import classnames from "classnames";
import { PatternFormat, NumericFormat } from "react-number-format";
// import TextInput from "../../TextInput"

import * as routes from "../../../constants/routes";
import {
    AddUser, editUser
} from "../../../actions/index";
import { alertService } from "./../../GlobalComponent/alertService";

class AddEditUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modelShow: this.props.AddEditUserModal,
            email: "",
            emailHelperText: "",
            firstName: "",
            firstNameHelperText: "",
            lastName: "",
            lastNameHelperText: "",
            phoneNumber: "",
            phoneNumberHelperText: "",
            editUserInfo: this.props.EditUser,
            error: this.props.error,
            // editUserInfo: {...this.props.EditUser}
        };
    }

    componentDidMount() {
        //console.log("dataProvider:::",dataProvider.getList());
        if (this.props.EditUser && this.props.mode === "Edit") {
            const { email, firstName, lastName, phoneNumber, isAdmin } = this.props.EditUser;
            this.setState({ editUserInfo: { ...this.props.EditUser }, email, firstName, lastName, phoneNumber, isAdmin });
        }
    }
    componentDidUpdate(prevProps) {
        if (this.props.AddEditUserModal && this.props.AddEditUserModal !== prevProps.AddEditUserModal) {
            this.setState({ modelShow: this.props.AddEditUserModal });
        }
        if (this.props.errors && this.props.errors !== prevProps.errors) {
            alertService.error(this.props.errors.errorMessage, { autoClose: true });
            this.setState({ email: '' });
            //this.setState({ AddPatientModal: true });
        }
        if (this.props.userAdd && this.props.userAdd !== prevProps.userAdd) {
            alertService.success("Invitation Sent Successfully.");
            this.props.handleClose();
            this.props.fetchUserList();
        }
        if (this.props.userEdit && this.props.userEdit !== prevProps.userEdit) {
            alertService.success("User updated successfully.", { autoClose: true });
            this.props.handleClose();
            this.props.fetchUserList();
        }
    }
    onChangeValueHandler = (e) => {
        let value = '';
        if (e.target.name === "markAsAdmin") {
            if (e.target.checked) {
                value = true;
            } else {
                value = false;
            }

        } else {
            value = e.target.value.trim();
        }

        this.setState({
            [e.target.id]: value,
            [`${e.target.id}HelperText`]: "",
        });
    };
    // onChangeValueHandlerTextInput = (data, id) => {
    //     console.log("Data in AddUser", data);
    //     let value = data?.trim();
    //     this.setState({
    //         [id]: value?.trim(),
    //         [`${id}HelperText`]: "",
    //     });
    // };
    ValidateData = async (e) => {
        this.setState({
            emailHelperText: ""
        });
        var validEmailRegex =
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (!this.state.email.match(validEmailRegex)) {
            this.setState({ emailHelperText: "Invalid email" });
            return false;
        }

        var validPhoneRegex = /^\d{10}$/;
        if (!this.state.phoneNumber.replace(/\W/g, "").match(validPhoneRegex)) {
            this.setState({ phoneNumberHelperText: "Invalid phone number" });
            return false;
        }

        let payload = {
            email: this.state.email
        };

        if (this.props.mode === "Add") {
            payload = {
                email: this.state.email,
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                phoneNumber: this.state.phoneNumber,
                isAdmin: this.state.isAdmin,
            }
            //await this.AddUserHandler(payload);
            this.props.AddUser(payload);
        }
        if (this.props.mode === "Edit") {
            payload = {
                email: this.state.email,
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                phoneNumber: this.state.phoneNumber,
                uid: this.props.EditUser.uid,
                isAdmin: this.state.isAdmin,
            }
            this.props.editUser(payload);
        }
    };
    handleClose = () => {
        this.setState({ modelShow: false })
    }
    render() {

        return (
            <Modal show={this.state.modelShow} onHide={this.props.handleClose} >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {this.props.mode === "Edit" ? 'Edit' : 'Invite'} User
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <small className="d-block text-right">IMPORTANT <span className="text-danger">*</span> are required fields.</small>
                    <Form on>
                        <Form.Group className="mb-3" >
                            <Form.Label>First Name<span className="text-danger">*</span></Form.Label>
                            <Form.Control type="firstName" className={classnames("form-control font-18", {
                                "is-invalid": this.state.firstNameHelperText,
                            })} placeholder="First Name" id="firstName" value={this.state.firstName}
                                onChange={(e) => this.onChangeValueHandler(e)} />

                            {/* <TextInput 
                            type="name"
                            // value={this.props.EditUser.firstName}
                            className={classnames("form-control font-18", {
                            "is-invalid": this.state.lastNameHelperText,
                            })}
                            onChange={(data) => this.onChangeValueHandler(data ,"firstName")}
                            /> */}
                            {this.state.lastNameHelperText && (
                                <div className="invalid-feedback">
                                    {this.state.lastNameHelperText}
                                </div>
                            )}
                            {this.state.firstNameHelperText && (
                                <div className="invalid-feedback">
                                    {this.state.firstNameHelperText}
                                </div>
                            )}
                        </Form.Group>
                        <Form.Group className="mb-3" >
                            <Form.Label>Last Name<span className="text-danger">*</span></Form.Label>
                            <Form.Control type="lastName" className={classnames("form-control font-18", {
                                "is-invalid": this.state.lastNameHelperText,
                            })} placeholder="Last Name" id="lastName" value={this.state.lastName}
                                onChange={(e) => this.onChangeValueHandler(e)} />
                            {/* <TextInput 
                            type="name"
                            // value={this.state.lastName}
                            className={classnames("form-control font-18", {
                            "is-invalid": this.state.lastNameHelperText,
                            })}
                            onChange={(data) => this.onChangeValueHandler(data ,"lastName")}
                            /> */}
                            {this.state.lastNameHelperText && (
                                <div className="invalid-feedback">
                                    {this.state.lastNameHelperText}
                                </div>
                            )}
                        </Form.Group>
                        <Form.Group className="mb-3" >
                            <Form.Label>Email address<span className="text-danger">*</span></Form.Label>
                            <Form.Control type="email" className={classnames("form-control font-18", {
                                "is-invalid": this.state.emailHelperText,
                            })} placeholder="Email address" id="email" value={this.state.email}
                                onChange={(e) => this.onChangeValueHandler(e)} disabled={this.props.mode === "Edit"} />
                            {/* <TextInput 
                            type="email"
                            // value={this.state.email}
                            className={classnames("form-control font-18", {
                                "is-invalid": this.state.emailHelperText,
                            })} 
                            onChange={(data) => this.onChangeValueHandler(data ,"email")}
                            /> */}
                            {this.state.emailHelperText && (
                                <div className="invalid-feedback">
                                    {this.state.emailHelperText}
                                </div>
                            )}
                        </Form.Group>
                        <Form.Group className="mb-3" >
                            <Form.Label>Phone Number<span className="text-danger">*</span></Form.Label>
                            <PatternFormat
                                format="(###) ###-####"
                                mask="_"
                                className={classnames("form-control font-18 h-auto", {
                                    "is-invalid": this.state.phoneNumberHelperText,
                                })}
                                placeholder="Phone Number"
                                id="phoneNumber"
                                name="phoneNumber"
                                onChange={(e) => this.onChangeValueHandler(e)}
                                value={this.state.phoneNumber}
                            />
                            {/* <TextInput 
                            type="tel"
                            value={this.props.EditUser.phoneNumber}
                            className={classnames("form-control font-18", {
                                "is-invalid": this.state.phoneNumberHelperText,
                            })} 
                            onChange={(data) => this.onChangeValueHandlerTextInput(data ,"phoneNumber")}
                            /> */}

                            {this.state.phoneNumberHelperText && (
                                <div className="invalid-feedback">
                                    {this.state.phoneNumberHelperText}
                                </div>
                            )}
                        </Form.Group>
                        <Form.Group className="mb-3" >
                            <Form.Check // prettier-ignore
                                type="switch"
                                id="isAdmin"
                                name="markAsAdmin"
                                label="Mark As Admin"
                                onChange={(e) => this.onChangeValueHandler(e)}
                                checked={this.state.isAdmin}
                            />
                        </Form.Group>
                    </Form>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={(e) => {
                        e.preventDefault();
                        this.ValidateData(e);
                    }} disabled={this.state.firstName == '' || this.state.lastName == '' || this.state.email == "" || this.state.phoneNumber == "" ? true : false}>
                        Submit
                    </Button>
                    <Button variant="primary" onClick={(e) => {
                        this.props.handleClose();
                    }}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

function mapStateToProps(state) {
    return {
        //provider: state.provider.providerList,
        currentUser: state.security.user,
        errors: state.errors,
        userAdd: state.user.userAdd,
        userEdit: state.user.userEdit,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            AddUser,
            fetchUserList,
            editUser
        },
        dispatch
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(AddEditUser);
