import React, { useEffect, useState } from "react";
import { BarChartDimensionalArray } from "react-chartjs-library";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Bar } from 'react-chartjs-2';
import { fetchLabel, GFG_sortFunction } from "./helper";
import { barColors } from "../../constants/barColors";
const BarChartComponent5 = (props) => {
  const [maxNumber, setMaxNumber] = useState();
  const [dataSets, setDataSets] = useState();

  const [labelArray, setLabelArray] = useState();
  const [groupByProject, setGroupByProject] = useState(null);
  const [totalUersProject, setTotalUersProject] = useState(null);
  useEffect(() => {
    const info1 = props?.info;

    const totalUersProject = props?.totalUersProject;
    setTotalUersProject(totalUersProject);
    const sortedArray = info1.sort(GFG_sortFunction);
    const descArr = [];
    const lengthArr = [];
    sortedArray.forEach((element, index1) => {
      const label = fetchLabel(element.usage_date);
      element.name.forEach((item, index2) => {
        descArr.push({
          ...element.name[index2],
          projectName: element.name[index2].name,
          label,
          [`total_cost_day`]: element.total_cost,
          [`total_cost_after_credits_day`]: element.total_cost_after_credits,
          usage_date: element.usage_date,
        });
      });
    });

    let labelArr = [];
    let dateArr = [];
    info1.forEach((element, index1) => {
      lengthArr.push(element.name.length);
      const label = fetchLabel(element.usage_date);
      labelArr.push(label);
      dateArr.push(element.usage_date);
    });

    let groupByPro = descArr.reduce((group, chart) => {
      const { projectName } = chart;
      const { usage_date } = chart;
      group[projectName] = (group[projectName]) ?? [];
      group[projectName].push(chart);
      return group;
    }, {});

    let groupByProNew = [];
    Object.keys(groupByPro).forEach((projectName) => {
      groupByProNew[projectName] = [];
      //groupByPro[projectName].forEach((value) => {

      dateArr.forEach((date) => {

        let dateObj = groupByPro[projectName].find(obj => {
          return obj.usage_date === date;
        });
        if (!dateObj) {
          groupByProNew[projectName].push({
            name: projectName, projectName: projectName, usage_date: date, label: fetchLabel(date), total_cost: 0,
            total_cost_after_credits: 0,
            total_cost_after_credits_day: 0,
            total_cost_day: 0,
          });
        } else {
          groupByProNew[projectName].push(dateObj);
        }
      })
      //});
    })

    groupByPro = groupByProNew;

    let dataValues = [];
    Object.keys(groupByPro).forEach((project, index) => {
      let data = [];
      //data[project]=[]
      groupByPro[project].forEach(val => {
        data.push(val.total_cost_after_credits);
      })
      dataValues.push({
        label: project,
        data: data,
        backgroundColor: barColors[index]
      },)
    });
    //console.log("dataValues:::",dataValues);
    const dataSets = dataValues;
    setDataSets(dataSets);

    const maxNumber = Math.max(...lengthArr);

    const tmpgroupByPro = Object.keys(groupByPro).sort();

    let newObj = {};

    tmpgroupByPro.forEach((item) => {

      newObj = { ...newObj, [item]: groupByPro[item] };
    });

    setGroupByProject(newObj);
    setLabelArray(labelArr);
    setMaxNumber(maxNumber);
  }, [props.info]);

  const setDataLabelCallback = (value, context, isStacked, maxNum) => {
    if (value > 0.0) {
      if (!isStacked) {
        return `$${value}`;
      }
      const arr = [0, 3, 6, 8, 10, 12, 15, 18, 21, 24, 27, 29, 30, 31];
      const isMatch = arr.includes(context.dataIndex);

      let i = maxNum - 1;
      while (i >= 0) {
        const valueItem =
          Object.values(groupByProject)[i][context.dataIndex]
            ?.total_cost_after_credits_day;
        if (context.datasetIndex === i) {
          if (isMatch) {
            return valueItem
              ? `$${Object.values(groupByProject)[i][context.dataIndex]
                .total_cost_after_credits_day
              }`
              : "";
          }
          return "";
        }
        if (valueItem) {
          break;
        } else {
          --i;
        }
      }

      // if (context.datasetIndex === maxNum - 1) {
      //   if (isMatch) {
      //     return `$${
      //       Object.values(groupByProject)[context.datasetIndex][
      //         context.dataIndex
      //       ].total_cost_after_credits_day
      //     }`;
      //   }
      //   return "";
      // }
    }
    return "";
  };

  const setYScaleCallback = (val, index) => {
    return `$${val}`;
  };

  const setXScaleCallback = (e, val, index) => {
    return index % 3 === 0 ? e.getLabelForValue(val) : "";
  };  
  const decimals = 0;
  const options = {
    plugins: {
      legend: {
        display: totalUersProject&&totalUersProject.length < 4 ? true : false,
        labels: {
          filter: (legendItem, data) => data.datasets[0].data[legendItem.index] != 0
        }
      },
      datalabels: {
        display: true,


      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem, data) {
            return tooltipItem.dataset.label + ": $" + (tooltipItem.formattedValue)
          },
          footer: function (items) {

            return 'Total: $' + items.reduce((a, b) => a + b.parsed.y, 0).toFixed(2)
          }
        }
      }
    },
    responsive: true,
    interaction: {
      mode: 'index',
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        ticks: {
          callback: function(value, index, values) {
              return '$' + value.toFixed(decimals);
          }
      }
      },
    },
  };

  const labels = labelArray;

  const data = {
    labels,
    datasets: dataSets
  };
  return (
    <div
      style={{
        // width: "-webkit-fill-available",
        width: "100%",
        height: "250px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {groupByProject && (

        <Bar options={options} data={data} />
      )}
    </div>
  );
};

export default BarChartComponent5;
