/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchUserList, disableUser, enableUser, assignedUserProjects } from "../../../actions/index";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Loader from "react-js-loader";
import moment from 'moment'
import DateRangePicker from 'rsuite/DateRangePicker';
import 'rsuite/DateRangePicker/styles/index.css';
import { startOfDay, endOfDay, addDays, subDays } from 'date-fns';
import { tableSort, GFG_sortFunction } from "../../Chart/helper";
// import anomalyList1 from "./anomalyList.json";
import AnomalyListItem from "./AnomalyListItem";
import AnomalyTableHeader from "./AnomalyTableHeader";
import { getAnomalyList } from "../../../api";

const columnInfo = [
  {
    title: "Start Date",
    dataKey: "usage_date",
    sortable: true,
  },
  {
    title: "Service",
    dataKey: "description",
    sortable: true,
  },
  {
    title: "Project/Account",
    dataKey: "name",
    sortable: true,
  },
  {
    title: "Billing Account ID",
    dataKey: "billing_account_id",
    sortable: true,
  },
  /* {
    title: "Anomaly",
    dataKey: "anomalies",
    sortable: false,
  }, */
  {
    title: "Severity",
    dataKey: "severity_type",
    sortable: true,
  },
  {
    title: "Cost of Anomaly",
    dataKey: "total_cost_after_credits",
    sortable: true,
  },
];

function AnomalyList(props) {
  //props.fetchUserList()
  const [sortedList, setSortedList] = useState(null);
  const [selectedProjects, setselectedProjects] = useState(0);
  const [selectedProject, setselectedProject] = useState('all');
  const [selProject, setseledProject] = useState('all');
  const defaultDateRange = {
    startDate: moment().format("yyyy-MM-01"),
    endDate: moment(endOfDay(new Date())).format("yyyy-MM-DD")
  };
  const [selectedDaterange, setselectedDaterange] = useState(defaultDateRange);
  const [filteredText, setFilteredText] = useState("");
  const searchKeyArr = ["description", "name","project_id", "billing_account_id"];
  const sortOrderValues = ["asc", "desc", null];
  const [sortIndex, setSortIndex] = useState([2, 2, 2, 2, 2, 2, 2]);
  // const anomalyList = anomalyList1.reverse();

  const [anomalyList, setAnomalyList] = useState(null);


  useEffect(() => {
    (async () => {
      if (props.currentUser.user_id) {
        await props.assignedUserProjects(props.currentUser.user_id);
      }

      //}
    })();
  }, [props.currentUser]);

  useEffect(async () => {

    if (props.selectedProjects !== undefined || selectedDaterange != '') {
      setselectedProjects(props.selectedProjects);
      setseledProject(props.selectedProjects);
      setFilteredText('');
      //if (props.selectedProjects > 0) {
      if (props.selectedProjects !== undefined && props.selectedProjects.length > 0 && selectedProjects && selectedProjects.length > 0) {
         const { anomalyList } = await getAnomalyList(selectedProject, selectedDaterange);
        if (anomalyList) {
          anomalyList.sort(GFG_sortFunction).reverse();
          setSortedList(anomalyList);
          setAnomalyList(anomalyList);
        }
      }


    }

  }, [props.selectedProjects, selectedProjects, selectedProject, selectedDaterange]);

  const implementSort = useCallback(
    (key, sortIndex) => {
      const sortList = [...sortedList];
      if (sortOrderValues[sortIndex]) {
        tableSort({ key, list: sortList, order: sortOrderValues[sortIndex] });
        setSortedList(sortList);
      }
      if (!sortOrderValues[sortIndex]) {
        if (!filteredText) {
          setSortedList(anomalyList);
        } else {
          const filertedList = anomalyList.filter(
            (item) =>
              item[searchKeyArr[0]].includes(filteredText)
            //|| item[searchKeyArr[1]].includes(filteredText) ||
            //item[searchKeyArr[2]].includes(filteredText)
          );
          setSortedList(filertedList);
        }
      }
    },
    [sortOrderValues, sortedList, filteredText]
  );

  const filterProject = useCallback(
    (value) => {
      setseledProject(value);
      const searchVal = value.toLowerCase();      
      if (value) {        
        const serviceFilteredList = (typeof filteredText === 'string' && filteredText !== '') ? anomalyList.filter(
          item => item[searchKeyArr[0]].toLowerCase().includes(filteredText.toLowerCase())
        ) : anomalyList; // Filter bas
        const filteredList = (typeof value === 'string' && value !== 'all') ? serviceFilteredList.filter(
          (item) =>
            item[searchKeyArr[0]].toLowerCase().includes(searchVal)
           || item[searchKeyArr[2]].toLowerCase().includes(searchVal)
          // || item[searchKeyArr[2]].includes(searchVal)
        ) : serviceFilteredList;
        setSortedList(filteredList);
        
      } else {
        setSortedList(anomalyList);
      } 
    },
    [sortedList, filteredText, anomalyList]
  );
  const filterList = useCallback(
    (value) => {
      setFilteredText(value);
      if (value) {        
        const projectFilteredList = typeof selProject === 'string' && selProject !== 'all' ? anomalyList.filter(
          item => item[searchKeyArr[2]].toLowerCase().includes(selProject.toLowerCase())
        ) : anomalyList; // Filter bas

        const searchVal = value.toLowerCase();
        const filteredList = projectFilteredList.filter(
          (item) =>
            item[searchKeyArr[0]].toLowerCase().includes(searchVal)
           || item[searchKeyArr[2]].toLowerCase().includes(searchVal)
          // || item[searchKeyArr[2]].includes(searchVal)
        );

        setSortedList(filteredList);        
      } else {
        setSortedList(anomalyList);
      }
    },
    [sortedList, filteredText, anomalyList]
  );
  const selectProject = useCallback(
    (value) => {
      setselectedProject(value);
    },
  );
  const Ranges = [
    {
      label: 'today',
      value: [startOfDay(new Date()), endOfDay(new Date())]
    },
    {
      label: 'yesterday',
      value: [startOfDay(addDays(new Date(), -1)), endOfDay(addDays(new Date(), -1))]
    },
    {
      label: 'last7Days',
      value: [startOfDay(subDays(new Date(), 6)), endOfDay(new Date())]
    },
    {
      label: 'Last 30 Days',
      value: [startOfDay(subDays(new Date(), 30)), endOfDay(new Date())]
    }
  ];

  const sortTable = (key, index) => {
    let sort_index = [...sortIndex];
    let s_idx = sort_index[index];
    sort_index = [2, 2, 2, 2, 2, 2, 2];
    switch (s_idx) {
      case 0:
        sort_index[index] = 1;
        setSortIndex(sort_index);
        implementSort(key, sort_index[index]);
        break;
      case 1:
        // sort_index[index] = 2;
        sort_index[index] = 0;
        setSortIndex(sort_index);
        implementSort(key, sort_index[index]);
        break;
      default:
        sort_index[index] = 0;
        setSortIndex(sort_index);
        implementSort(key, sort_index[index]);
        break;
    }
  };
  const handleSelect = (ranges) => {
    if (ranges && ranges.length > 0) {
      let startDate = moment(ranges[0]).format("yyyy-MM-DD");
      let endDate = moment(ranges[1]).format("yyyy-MM-DD");
      setselectedDaterange({ startDate: startDate, endDate: endDate });
    }
    else {
      setselectedDaterange(defaultDateRange);
    }
  }
  return (
    <Container fluid className="pt-5 pb-2 mt-5">
      <Row>
        <Col xs={12} className="pr-1 pl-1">
          <h4 className="mb-4">Cost Anomalies</h4>


          {selectedProjects && selectedProjects.length > 0 ? (
            sortedList ? (
              <>
                <Form
                  className="mb-3"
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <Row className="m-0">
                    <Col md={4} xs={12} className="pl-0">
                      <Form.Group className="mb-3 d-flex align-items-center" >
                        <Form.Label className="mb-0 mr-2">Projects</Form.Label>
                        {/* <Form.Select onChange={(e) => selectProject(e.target.value)} value={selectedProject} className="form-control ">
                          {selectedProjects.length > 1 ? <option id="all" value="all">All Projects</option> : ''}
                          {selectedProjects.map((project, index) => {
                            return <option value={project.projectId}>{project.projectName}</option>
                          })
                          }
                        </Form.Select> */}
                        <Form.Select onChange={(e) => filterProject(e.target.value)} value={selProject} className="form-control ">
                          {selectedProjects.length > 1 ? <option id="all" value="all">All Projects</option> : ''}
                          {selectedProjects.map((project, index) => {
                            return <option value={project.projectId}>{project.projectName}</option>
                          })
                          }
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col md={3} xs={12} className="">
                      <Form.Group
                        className="mb-3 position-relative"
                        controlId="exampleForm.ControlInput1"
                      >
                        <img
                          className="filter-icon"
                          src="images/filter-icon.svg"
                          alt="filter icon"
                        />
                        <Form.Control
                          type="text"
                          className="pl-5"
                          placeholder="Filter by Service"
                          value={filteredText}
                          onChange={(e) => {
                            filterList(e.target.value);
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={4} xs={12} className="">
                      <Form.Group
                        className="mb-3 position-relative custom-daterangepicker"
                        controlId="exampleForm.ControlInput1"
                      >
                        <DateRangePicker placeholder="Select Date Range" format="MM/dd/yyyy" ranges={Ranges} onChange={handleSelect} />

                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
                <div className="table-responsive">
                <Table bordered>
                  <thead>
                    <AnomalyTableHeader
                      columnInfo={columnInfo}
                      sortTable={sortTable}
                      sortIndex={sortIndex}
                    />
                  </thead>
                  <tbody>
                    {sortedList.length > 0 ?
                      (
                        sortedList.map((item) => (
                          <AnomalyListItem item={item} filteredText={filteredText} />
                        ))
                      )
                      : (<td className="text-center" colSpan={7}>No Record Found</td>)}
                  </tbody>
                </Table>
                </div>
              </>
            ) : (
              <div
                className="flex"
                style={{
                  width: "100%",
                  height: "50%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Loader type="spinner-circle" bgColor={"gray"} size={50} />
              </div>
            )
          ) : (<>
            <div className="d-flex align-items-center justify-content-center h-100">
              <div class="cust-card p-5 text-center">
                <h1 className="m-5">No Project Found</h1>
              </div>


            </div>
          </>)}
        </Col>
      </Row>
    </Container>
  );
}

function mapStateToProps(state) {
  return {
    //provider: state.provider.providerList,
    currentUser: state.security.user,
    user: state.user.userList,
    userStatus: state.user.userStatus,
    selectedProjects: state.userProject.selectedProjects
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchUserList,
      disableUser,
      enableUser,
      assignedUserProjects,
    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(AnomalyList);
