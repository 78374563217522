import React, { useEffect, useState } from "react";
import { PointChart } from "react-chartjs-library";
import { fetchLabel, GFG_sortFunction } from "./helper";
import { Line } from 'react-chartjs-2';
import { barColors } from "../../constants/barColors";

function LineChartComponent(props) {
  const { info } = props;
  const [dataSets, setDataSets] = useState();
  const [labelArray, setLabelArray] = useState();
  const [groupByService, setGroupByService] = useState(null);
  const datakey = "total_cost";

  useEffect(() => {
    const tmpGroupData = Object.keys(info).sort(GFG_sortFunction);

    let newObj = {};
    let labelArr = [];
    tmpGroupData.forEach((item) => {
      newObj = { ...newObj, [item]: info[item] };
      labelArr.push(fetchLabel(item));
    });

    const flattendObj = [];
    Object.values(newObj).forEach((item, index) => {
      item.forEach((element, index2) => {
        flattendObj.push({
          ...element,
          service: element.description,
          usage_date: tmpGroupData[index],
        });
      });
    });

    if (flattendObj.length) {
      const groupBySer = flattendObj.reduce((group, chart) => {
        const { service } = chart;
        group[service] = group[service] ?? [];
        group[service].push(chart);
        return group;
      }, {});

      let dataValues = [];

      Object.keys(groupBySer).forEach((project, index) => {
        let data = [];
        //console.log(groupByPro[project]);
        //data[project]=[]
        groupBySer[project].forEach(val => {
          if (val.total_cost > 0) {
            data.push(val.total_cost);
          } else {
            data.push('');
          }
        })
        let isAllZero = data.every(item => item == 0);
        if (!isAllZero) {
          dataValues.push({
            label: project,
            data: data,
            pointStyle: 'triangle',
            pointRadius: 5,
            pointHoverRadius: 10,
            borderWidth: 1,
            backgroundColor: barColors[index],
            borderColor: barColors[index],
          })
        }
      });
      const dataSets = dataValues;
      setDataSets(dataSets);

      const tmpgroupBySer = Object.keys(groupBySer).sort();

      let newObj = {};
      tmpgroupBySer.forEach((item) => {
        newObj = { ...newObj, [item]: groupBySer[item] };
      });

      setGroupByService(newObj);
    }

    setLabelArray(labelArr);
  }, [props.info]);

  const setYScaleCallback = (val, index) => {
    return `$${val}`;
  };

  const setXScaleCallback = (e, val, index) => {
    return index % 4 === 0 ? `${e.getLabelForValue(index)} ` : "";
  };
  const decimals = 0;
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        labels: {
          filter: (legendItem, data) => data.datasets[0].data[legendItem.index] != 0
        }
      },
      datalabels: {
        display: true,
      },      
      tooltip: {
        filter: tooltipItem => tooltipItem.dataset.data[tooltipItem.dataIndex] > 0,
        callbacks: {
          label: function (tooltipItem, data) {

            return tooltipItem.dataset.label + ": $" + (tooltipItem.formattedValue)
          },
        }
      },      
    },
    scales: {        
      y: {        
        ticks: {
          callback: function(value, index, values) {
              return '$' + value.toFixed(decimals);
          }
      }
      },
    },
  };
  const labels = labelArray;

  const data = {
    labels,
    datasets: dataSets
  };
  return (
    <div
      style={{
        // width: "-webkit-fill-available",
        width: "100%",
        height: "250px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {groupByService && (
        // <PointChart
        //   info={groupByService}
        //   labelArray={labelArray}
        //   dataKey={datakey}
        //   colorArray={[
        //     "rgba(228, 163, 245, 1)",
        //     "rgba(153, 115, 102, 1)",
        //     "rgba(4,47,102, 1)",
        //     "rgba(204, 109, 0, 1)",
        //     "rgba(0, 144, 214, 1)",
        //     "rgba(105,189,210, 1)",
        //     "rgba(70, 18, 84, 1)",
        //     "rgba(153, 115, 102, 1)",
        //     "rgba(224,123,57, 1)",
        //   ]}
        //   pointStyle={[
        //     "circle",
        //     "cross",
        //     "rect",
        //     "triangle",
        //     "star",
        //     "rectRot",
        //     "crossRot",
        //     "triangle",
        //     "rect",
        //   ]}
        //   setYScaleCallback={setYScaleCallback}
        //   setXScaleCallback={setXScaleCallback}
        //   xtickfont={{ size: 10, color: null }}
        //   ytickfont={{ size: null, color: null }}
        // // tooltiptitleCallback={tooltiptitleCallback}
        // // tooltiplabelCallback={tooltiplabelCallback}
        // />
        <Line options={options} data={data} />
      )}
    </div>
  );
}
export default LineChartComponent;
