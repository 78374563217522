import React from "react";
import { Redirect, Route } from "react-router-dom";

function LoginRoute({ component: Component, ...rest }) {
  const tokenPresent = sessionStorage.getItem("jwtToken");
  //const tokenPresent =  localStorage.getItem("jwtToken");

  return (
    <Route
      {...rest}
      render={(props) => {
        if (tokenPresent) {
          // not logged in so redirect to login page with the return url
          return (
            <Redirect
              to={{ pathname: "/dashboard", state: { from: props.location } }}
            />
          );
        }

        // authorized so return component
        return <Component {...props} />;
      }}
    />
  );
}

export default LoginRoute;
