import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Container from "react-bootstrap/Container";
import Form from 'react-bootstrap/Form';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Chart from "../../Chart";
import Loader from "react-js-loader";
import { fetchImage } from "./helper";
import { assignedUserProjects } from "../../../actions/index";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.costInfo = this.costInfo.bind(this);
    this.state = {
      section1Info: null,
      section2Info: null,
      section3Info: null,
      selectedProjects: 0,
      selectedProject: '',
    };
  }

  async componentDidMount() {
    let currentUser = this.props.currentUser;
    if (currentUser == "" || Object.keys(currentUser).length == 0) {
      //window.location.href = "/";
      //return false;
    }
    if (currentUser.user_id && currentUser.user_id != '') {
      await this.props.assignedUserProjects(currentUser.user_id);
    }
  }

  async componentDidUpdate(prevProps) {
    if (this.props.selectedProjects && prevProps.selectedProjects !== this.props.selectedProjects) {
      this.setState({ selectedProjects: this.props.selectedProjects });
      if (this.props.selectedProjects.length == '1') {
        this.setState({ selectedProject: this.props.selectedProjects[0].projectId });
      }
    }
    if (this.props.currentUser && prevProps.currentUser !== this.props.currentUser) {
      let currentUser = this.props.currentUser;
      if (currentUser.user_id && currentUser.user_id != '') {
        await this.props.assignedUserProjects(currentUser.user_id);
      }
    }
  }

  costInfo(info) {
    const data1 = info.this_month_spend;
    const key2 = "30days_anomaly_cost";
    const data2 = info[key2];
    this.setState({ section1Info: data1, section2Info: data2 });
  }
  selectProject(e) {
    let selectedProject = e.target.value;
    this.setState({ selectedProject: selectedProject });
  }
  render() {
    return (
      <Container fluid className="pt-5 pb-2 mt-5 ">
        {this.state.selectedProjects != 0 && <>
          <Form.Group className="mb-3 d-flex align-items-center" >
            <Form.Label className="mb-0 mr-2">Projects</Form.Label>
            <Form.Select onChange={(e) => this.selectProject(e)} value={this.state.selectedProject} className="form-control min-width-300">
              <option value="">Select Project</option>
              {this.state.selectedProjects.length > 1 ? <option id="all" value="all">All Projects</option> : ''}
              {this.state.selectedProjects.map((project, index) => {
                return <option value={project.projectId}>{project.projectName}</option>
              })
              }
            </Form.Select>
          </Form.Group>
          {this.state.selectedProject != '' &&
            <>
              <Row className="py-2">
                <Col md={4} sm={6} xs={12} className="pr-1 pl-1">
                  {this.state.section1Info ? (
                    <div className="cust-card p-3 text-center">
                      <p className="mb-1 d-flex align-items-center justify-content-center text-light-gray">
                        {fetchImage(this.state.section1Info?.ratio?.trim())}
                        {this.state.section1Info ? (
                          `${this.state.section1Info?.ratio}  ${Math.abs(this.state.section1Info?.percentage) || ""
                          }%  or $${this.state.section1Info?.cost_ratio}`
                        ) : (
                          <div style={{ color: "white" }}>Ratio</div>
                        )}
                      </p>
                      <h5 className="mb-1">
                        {this.state.section1Info ? (
                          `$${this.state.section1Info?.total_this_month_cost}`
                        ) : (
                          <div style={{ color: "white" }}>Amount</div>
                        )}
                      </h5>
                      <p className="mb-0 text-light-gray">
                        {this.state.section1Info ? (
                          <>This month spend</>
                        ) : (
                          <div style={{ color: "white" }}>Amount</div>
                        )}
                      </p>
                    </div>
                  ) : (
                    <Loader type="spinner-circle" bgColor={"gray"} size={20} />
                  )}
                </Col>
                <Col md={4} sm={6} xs={12} className="pr-1 pl-1">
                  <div className="cust-card p-3 text-center">
                    {/* <p className="mb-1 d-flex align-items-center justify-content-center text-light-gray">
               */}
                    <p
                      className="mb-1 d-flex align-items-center justify-content-center"
                      style={{ color: "white" }}
                    >
                      {/* <img
                  className="mr-2"
                  src="images/up-arrow.svg"
                  height={18}
                  width={18}
                  alt="arrow"
                /> */}
                      Text
                    </p>
                    <h5 className="mb-1">NA</h5>
                    <p className="mb-0 text-light-gray">This month forecast</p>
                  </div>
                </Col>
                <Col md={4} sm={6} xs={12} className="pr-1 pl-1">
                  {this.state.section2Info ? (
                    <div className="cust-card p-3 text-center">
                      <p className="mb-1 d-flex align-items-center justify-content-center text-light-gray">
                        {fetchImage(this.state.section2Info?.ratio?.trim())}
                        {this.state.section2Info ? (
                          `${this.state.section2Info?.ratio}  ${Math.abs(this.state.section2Info?.percentage) || 0
                          }%  or $${this.state.section2Info?.cost_ratio}`
                        ) : (
                          <div style={{ color: "white" }}>Ratio</div>
                        )}
                      </p>
                      <h5 className="mb-1">
                        {this.state.section2Info ? (
                          `$${this.state.section2Info?.total_this_month_cost}`
                        ) : (
                          <div style={{ color: "white" }}>Amount</div>
                        )}
                      </h5>
                      <p className="mb-0 text-light-gray">
                        {this.state.section2Info ? (
                          <>30-day anomaly cost</>
                        ) : (
                          <div style={{ color: "white" }}>Amount</div>
                        )}
                      </p>
                    </div>
                  ) : (
                    <Loader type="spinner-circle" bgColor={"gray"} size={20} />
                  )}
                </Col>
              </Row>

              <Chart costInfo={this.costInfo} selectedProject={this.state.selectedProject} totalUersProject={this.state.selectedProjects} />
            </>
          }
        </>}
        {this.state.selectedProject == '' && <>         
          <div className="d-flex align-items-center justify-content-center h-100">
              <div class="cust-card p-5 text-center">
                <h1 className="m-5">Please Select Project</h1>
              </div>
            </div>
        </>}
        {this.state.selectedProjects.length == 0 &&
          <div className="d-flex align-items-center justify-content-center h-100">
            <div class="cust-card p-5 text-center">
              <h1 className="m-5">No Project Found</h1>
            </div>
          </div>
        }
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    //ptpuser: state.security.ptpuser,
    currentUser: state.security.user,
    selectedProjects: state.userProject.selectedProjects
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      assignedUserProjects
      //
    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
